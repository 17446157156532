import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import history from 'containers/App/history';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { Button as AntButton } from 'antd';

const ChangePasswordForm = (props) => {
  const { onChangePassword, errorMessage, t } = props;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onClickButtonChangePassword = () => {
    onChangePassword(oldPassword, newPassword);
  };

  const onClickButtonCancel = () => {
    history.push('/');
  };

  const handleChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const onChangeInputNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const onChangeInputConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onKeyDownInputConfirmPassword = (event) => {
    if (event.key === 'Enter') {
      onChangePassword(oldPassword, newPassword);
    }
  };

  return (
    <Form className="form">
      <Alert color="danger" isOpen={!!errorMessage}>
        {errorMessage}
      </Alert>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-old_password">
          {t('change-password.old-password')}
        </Label>
        <Input
          type="password"
          name="old_password"
          id="form-input-old_password"
          placeholder={t('change-password.old-password-placeholder')}
          value={oldPassword}
          onChange={handleChangeOldPassword}
        />
      </FormGroup>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-new_password">
          {t('change-password.new-password')}
        </Label>
        <Input
          type="password"
          name="new_password"
          id="form-input-new_password"
          placeholder={t('change-password.new-password-placeholder')}
          value={newPassword}
          onChange={onChangeInputNewPassword}
        />
      </FormGroup>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-confirm_password">
          {t('change-password.confirm-password')}
        </Label>
        <Input
          type="password"
          name="confirm_password"
          id="form-input-confirm_password"
          placeholder={t('change-password.confirm-password-placeholder')}
          value={confirmPassword}
          onChange={onChangeInputConfirmPassword}
          onKeyDown={onKeyDownInputConfirmPassword}
        />
      </FormGroup>
      <Button className="account__btn" style={{ marginTop: 30 }} type="button" onClick={onClickButtonChangePassword} color="primary">
        {t('change-password.confirm')}
      </Button>

      <div className="account__cancel" style={{ bottom: 0, position: 'relative', marginTop: 20 }}>
        <AntButton type="link" onClick={onClickButtonCancel} style={{ fontSize: 11 }}>
          {t('change-password.cancel')}
        </AntButton>
      </div>
    </Form>
  );
};

export default withTranslation('common')(ChangePasswordForm);
