import React from 'react';
import { Collapse, Descriptions } from 'antd';
import { abapTextToHtml } from 'utils';

const CollapseInformation = (props) => {
  const { data } = props;

  let groupPanels = [];
  if (data.groups) {
    groupPanels = data.groups.map((group, groupIndex) => {
      let groupItems = data.items
        .filter((item) => {
          return item.groupId === group.id;
        })
        .map((item, itemIndex, arr) => {
          let span = 1;
          if (
            arr.length &&
            itemIndex + 1 < arr.length &&
            arr[itemIndex + 1].newLine &&
            arr[itemIndex + 1].newLine === "X"
          ) {
            span = 10;
          }

          return (
            <Descriptions.Item label={item.label} key={groupIndex + 1 + '-' + (itemIndex + 1)} span={span}>
              {abapTextToHtml(item.value)}
            </Descriptions.Item>
          );
        });

      return (
        <Collapse.Panel header={group.label} key={groupIndex + 1}>
          <Descriptions size="small" layout="vertical" column={{ xxl: 5, xl: 4, lg: 3, md: 2, sm: 1, xs: 1 }}>
            {groupItems}
          </Descriptions>
        </Collapse.Panel>
      );
    });
  }

  let defaultKeys = groupPanels.map((group, index) => group.key);
  return <Collapse defaultActiveKey={defaultKeys}>{groupPanels}</Collapse>;
};

export default CollapseInformation;
