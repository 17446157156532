import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLinkItem from './TopbarMenuLinkItem';
import TopbarMenuItem from './TopbarMenuItem';
import Ava from 'shared/img/account/ava.png';

import nl from 'shared/img/language/nl.png';
import en from 'shared/img/language/en.png';
import fr from 'shared/img/language/fr.png';
import de from 'shared/img/language/de.png';

const TopbarProfile = (props) => {
  const { t, username, role, logout, changeRole, changeLanguage } = props;
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={toggle}>
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
        <p className="topbar__avatar-name">
          {username}
          {role.selection ? (
            <>
              <br /> {role.selection.roleName} {role.selection.sapId}
            </>
          ) : null}
        </p>

        <DownIcon className="topbar__icon" />
      </button>
      {collapse && <button type="button" className="topbar__back" onClick={toggle} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <div className="topbar_language-container">
            <button className="topbar__language-btn" type="button" onClick={() => changeLanguage('en')}>
              <span className="topbar__language-btn-title">
                <img src={en} alt="en" />
              </span>
            </button>
            <button className="topbar__language-btn" type="button" onClick={() => changeLanguage('nl')}>
              <span className="topbar__language-btn-title">
                <img src={nl} alt="nl" />
              </span>
            </button>
            <button className="topbar__language-btn" type="button" onClick={() => changeLanguage('fr')}>
              <span className="topbar__language-btn-title">
                <img src={fr} alt="fr" />
              </span>
            </button>
            <button className="topbar__language-btn" type="button" onClick={() => changeLanguage('de')}>
              <span className="topbar__language-btn-title">
                <img src={de} alt="de" />
              </span>
            </button>
          </div>
          <div className="topbar__menu-divider" />
          <TopbarMenuLinkItem title={t('topbar.change-email')} icon="list" path="/auth/change_email" onClick={toggle} />
          <TopbarMenuLinkItem title={t('topbar.change-password')} icon="lock" path="/auth/change_password" onClick={toggle} />

          { role.selection ? (
            <>
              <div className="topbar__menu-divider" />
              <TopbarMenuItem title={t('topbar.change-role')} icon="users" onClick={changeRole} />
            </>
          ) : null}
          <div className="topbar__menu-divider" />
          <TopbarMenuItem title={t('topbar.logout')} icon="exit" onClick={logout} />
        </div>
      </Collapse>
    </div>
  );
};

export default withTranslation('common')(TopbarProfile);
