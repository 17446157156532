import React from "react";
import { LeafletMap, Panel } from "shared/components";

const Map = (props) => {
  const settings = {
    layers: props.data.layers || null,
    geoJson: {
      features: [],
    },
    draw: props.data.draw || null
  };

  // the geometries are delivered as strings
  if (props.data.features && props.data.features.length) {
    settings.geoJson.features = props.data.features
      .map((feature) => {
        try {
          feature.type = "Feature";

          if (typeof feature.geometry === "string") {
            feature.geometry = JSON.parse(feature.geometry);
          }
          if (feature.properties && feature.properties.length) {
            feature.properties = feature.properties.reduce(
              (accumulator, currentValue) => {
                return {
                  ...accumulator,
                  [currentValue.name]: currentValue.value,
                };
              },
              {}
            );
          }

          return feature;
        } catch (e) {
          return null;
        }
      })
      .filter((feature) => {
        return feature !== null;
      });
  }

  return (
    <>
      {props.data.hidePanel && props.data.hidePanel.toUpperCase() === "X" ? (
        <LeafletMap customId={`comp-map-${props.id}`} settings={settings} />
      ) : (
        <Panel
          title={
            props.data.title && props.data.title !== ""
              ? props.data.title
              : null
          }
          titleNav={
            props.data.titleNavigationPath &&
            props.data.titleNavigationPath !== ""
              ? props.data.titleNavigationPath
              : null
          }
        >
          <LeafletMap customId={`comp-map-${props.id}`} settings={settings} esriToken={props.esriToken} />
        </Panel>
      )}
    </>
  );
};

export default Map;
