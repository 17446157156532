import React from 'react';
import { Panel, TableList } from 'shared/components';

const Table = (props) => {
  return (
    <Panel
      title={props.data.title && props.data.title !== '' ? props.data.title : null}
      titleNav={props.data.titleNavigationPath && props.data.titleNavigationPath !== '' ? props.data.titleNavigationPath : null}
    >
      <TableList customClass="mui-datatable-list" data={props.data.data} columns={props.data.columns} config={props.data.config} />
    </Panel>
  );
};

export default Table;
