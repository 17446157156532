import React from 'react';
import { withTranslation } from 'react-i18next';

const Loading = ({ t }) => (
  <>
    <div className="load">
      <div className="load__icon-wrap">
        <svg className="load__icon">
          <path d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
        <div style={{ display: 'block', zIndex: 10000, marginTop: 25, color: '#666', textAlign: 'center', width: '100%' }}>{t('loading.fetching-data')}</div>
      </div>
    </div>
  </>
);

export default withTranslation('common')(Loading);
