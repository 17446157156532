import { combineReducers } from 'redux';
import password from './passwordReducer';
import login from './loginReducer';
import email from './emailReducer';

const authReducers = combineReducers({
  password,
  login,
  email,
});

export default authReducers;
