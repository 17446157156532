import React from 'react';
import { withTranslation } from 'react-i18next';
import { Menu, Select } from 'antd';
import history from 'containers/App/history';

const ResponsiveTabs = (props) => {
  const { items, selectedKey } = props;

  const groups = [];
  for (let i = 0; i < items.length; i++) {
    const group = items[i].group;
    if (groups.indexOf(group) === -1) {
      groups.push(group);
    }
  }

  const onChangeSelect = (value) => {
    const selectedItem = items.filter((item) => {
      return item.key === value;
    })[0];
    history.push(selectedItem.path);
  };

  const onChangeManu = (value) => {
    const selectedItem = items.filter((item) => {
      return item.key === value.key;
    })[0];
    history.push(selectedItem.path);
  };

  return (
    <div className="responsive-tabs">
      <div className="responsive-tabs__wrapper tabs__wrapper--desktop">
        {/* <div className="responsive-tabs__wrapper tabs__wrapper--mobile"> */}
        <Menu onClick={onChangeManu} selectedKeys={[selectedKey]} mode="horizontal">
          {items.map((item) => {
            return <Menu.Item key={item.key}>{item.name}</Menu.Item>;
          })}
        </Menu>
      </div>
      {/* <div className="responsive-tabs__wrapper tabs__wrapper--desktop"> */}
      <div className="responsive-tabs__wrapper tabs__wrapper--mobile">
        <Select className="selector" bordered={true} size="large" defaultValue={selectedKey} style={{ width: '100%' }} onChange={onChangeSelect}>
          {groups.map((group) => {
            return (
              <Select.OptGroup key={group} label={group}>
                {items
                  .filter((item) => {
                    return item.group === group;
                  })
                  .map((item) => {
                    return (
                      <Select.Option key={item.key} value={item.key}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
              </Select.OptGroup>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default withTranslation('common')(ResponsiveTabs);
