import React from 'react';

const TopbarMenuItem = (props) => {
  const { title, icon, onClick } = props;

  return (
    <div className="topbar__link" onClick={onClick}>
      <span className={`topbar__link-icon lnr lnr-${icon}`} />
      <p className="topbar__link-title">{title}</p>
    </div>
  );
};

export default TopbarMenuItem;
