import { GET_USERROLE_REQUEST, GET_USERROLE_SUCCESS, GET_USERROLE_FAILURE, SET_USERROLE } from 'redux/actions/user/userRoleActions';

const initialState = {
  list: {
    isFetching: false,
    data: {
      roles: null,
    },
    error: null,
  },
  selection: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USERROLE:
      return {
        ...state,
        selection: action.payload.data.role,
      };

    case GET_USERROLE_REQUEST:
      return {
        ...state,
        list: {
          isFetching: true,
          data: null,
          error: null,
        },
        selection: null,
      };

    case GET_USERROLE_FAILURE:
      let errorCode = 500;
      let errorMessage = 'An unknown error occured';
      if (action.payload.error) {
        if (action.payload.error.message) {
          errorMessage = action.payload.error.message;
        }
        if (action.payload.error.code) {
          errorCode = action.payload.error.code;
        }
      }

      return {
        ...state,
        list: {
          isFetching: false,
          data: [],
          error: {
            code: errorCode,
            message: errorMessage,
          },
        },
        selection: null,
      };

    case GET_USERROLE_SUCCESS:
      return {
        ...state,
        list: {
          isFetching: false,
          data: action.payload.data,
          error: null,
        },
      };

    default:
      return state;
  }
}
