import React, { useEffect } from "react";
import { compose } from "redux";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { notification } from "antd";
import { withTranslation } from "react-i18next";
import { pageActions } from "redux/actions/pages/pageActions";
import { Container, Row, Col } from "reactstrap";
import { Loading } from "shared/components";
import Breadcrumb from "./components/Breadcrumb";
import Table from "./components/Table";
import InformationCollapse from "./components/InformationCollapse";
import InformationDivider from "./components/InformationDivider";
import Map from "./components/Map";
import Title from "./components/Title";
import Subheader from "./components/Subheader";
import Tabs from "./components/Tabs";
import Statistic from "./components/Statistic";
import Chart from "./components/Chart";
import Html from "./components/Html";
import Form from "./components/Form";
import { mapActions } from "redux/actions/map/mapActions";

const PageTemplate = (props) => {
  const { t, dispatch, page, pageSettings, map } = props;
  const { id } = useParams();
  const notifications = page && page.data ? page.data.notifications : undefined;


  useEffect(() => {
    notification.destroy();

    dispatch(pageActions.get(pageSettings.api, id));
    dispatch(mapActions.getEsriToken())
  }, [dispatch, pageSettings.api, id]);

  useEffect(() => {
    const notifications =
      page && page.data && page.data.notifications
        ? page.data.notifications
        : [];

    for (let i = 0; i < notifications.length; i++) {
      let item = notifications[i];

      notification[item.type.toLowerCase()]({
        message: item.title,
        description: item.body,
        duration: item.duration,
        top: 70,
      });
    }

    dispatch(pageActions.deletePageNotifications(pageSettings.api, id));
  }, [dispatch, page, notifications, pageSettings.api, id]);

  const createComponent = {
    breadcrumb: (props) => <Breadcrumb data={props} />,
    table: (props) => <Table data={props} />,
    informationcollapse: (props) => <InformationCollapse data={props} />,
    informationdivider: (props) => <InformationDivider data={props} />,
    title: (props) => <Title data={props} />,
    subheader: (props) => <Subheader data={props} />,
    statistic: (props) => <Statistic data={props} />,
    tabs: (props) => <Tabs data={props} />,
    map: (props, id) => map.esriToken ? <Map data={props} id={id} esriToken={map.esriToken || ""} /> : <></>,
    chart: (props) => <Chart data={props} />,
    html: (props) => <Html data={props} />,
    form: (props) => <Form data={props} id={id} api={pageSettings.api} />,
  };

  if (page && page.isFetching) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  const getComponentSize = (size) => {
    if (
      Object.keys(size)
        .map((prop) => size[prop])
        .every((item) => item === "")
    ) {
      return null;
    }

    return Object.keys(size)
      .map((prop) => {
        return { id: prop, value: size[prop] };
      })
      .filter((item) => item.value !== "")
      .reduce((obj, item) => {
        return { ...obj, [item.id]: item.value };
      }, {});
  };

  const getPageComponents = (pageData) => {
    if (pageData.errorMessage && pageData.errorMessage !== "") {
      notification["error"]({
        message: t("error"),
        description: pageData.errorMessage,
        duration: 6,
        style: {
          marginTop: 60,
        },
      });
    }

    // first extract the Rows
    // components following each other with a set size belong to the same row
    let rows = [];
    let row = [];
    for (var i = 0; i < pageData.pageComponents.length; i++) {
      const comp = pageData.pageComponents[i];

      // check if the componentType is known
      if (!comp.type || !createComponent[comp.type.toLowerCase()]) {
        continue;
      }

      // create the components per row
      const isFullRow = Object.keys(comp.size)
        .map((prop) => comp.size[prop])
        .every((item) => item === "");
      if (isFullRow) {
        if (row.length) {
          rows.push(row);
          row = [];
        }
        rows.push([
          {
            component: createComponent[comp.type.toLowerCase()](
              pageData[comp.data],
              i + 1
            ),
          },
        ]);
      } else if (comp.newRow && comp.newRow.toUpperCase() === "X") {
        if (row.length) {
          rows.push(row);
          row = [];
        }
        row.push({
          component: createComponent[comp.type.toLowerCase()](
            pageData[comp.data],
            i + 1
          ),
          size: getComponentSize(comp.size),
        });
      } else {
        row.push({
          component: createComponent[comp.type.toLowerCase()](
            pageData[comp.data],
            i + 1
          ),
          size: getComponentSize(comp.size),
        });
      }
    }
    if (row.length) {
      rows.push(row);
    }

    const components = rows.map((row, rowIndex) => {
      return (
        <Row key={`page-row-${rowIndex + 1}`}>
          {row.map((comp, colIndex) => {
            return (
              <Col
                {...comp.size}
                key={`page-col-${rowIndex + 1}-${colIndex + 1}`}
              >
                {comp.component}
              </Col>
            );
          })}
        </Row>
      );
    });

    // create the rows and the components inside
    return components;
  };

  let components = null;
  if (page && page.data && page.data.pageComponents) {
    components = getPageComponents(page.data);
  }

  return <Container>{components}</Container>;
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const api = ownProps.pageSettings.api;
  let page = null;
  if (state.pages.page && state.pages.page[api]) {
    if (id !== undefined) {
      page = state.pages.page[api][id];
    } else {
      page = state.pages.page[api];
    }
  }
  return {
    page,
    map: state.map
  };
};

const PageTemplateContainer = compose(
  withTranslation("common"),
  connect(mapStateToProps)
)(PageTemplate);

export { PageTemplateContainer as PageTemplate };
