import {
  RESET_AUTHPASSWORD,
  CHANGE_AUTHPASSWORD_REQUEST,
  CHANGE_AUTHPASSWORD_SUCCESS,
  CHANGE_AUTHPASSWORD_FAILURE,
  RESET_AUTHPASSWORD_REQUEST,
  RESET_AUTHPASSWORD_FAILURE,
  RESET_AUTHPASSWORD_SUCCESS,
  CONFIRM_AUTHPASSWORD_REQUEST,
  CONFIRM_AUTHPASSWORD_SUCCESS,
  CONFIRM_AUTHPASSWORD_FAILURE,
} from 'redux/actions/auth/passwordActions';

const initialState = {
  isFetching: false,
  pendingConfirmation: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_AUTHPASSWORD:
      return {
        ...state,
        isFetching: false,
        pendingConfirmation: false,
        error: null,
      };

    case RESET_AUTHPASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        pendingConfirmation: false,
        error: null,
      };

    case RESET_AUTHPASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        pendingConfirmation: false,
        error: action.payload.message,
      };

    case RESET_AUTHPASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        pendingConfirmation: true,
        error: null,
      };

    case CONFIRM_AUTHPASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        pendingConfirmation: true,
        error: null,
      };

    case CONFIRM_AUTHPASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        pendingConfirmation: true,
        error: action.payload.message,
      };

    case CONFIRM_AUTHPASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        pendingConfirmation: false,
        error: null,
      };

    case CHANGE_AUTHPASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };

    case CHANGE_AUTHPASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };

    case CHANGE_AUTHPASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
      };

    default:
      return state;
  }
}
