import { Auth } from 'aws-amplify';
import history from 'containers/App/history';

export const RESET_AUTHEMAIL = 'RESET_AUTHEMAIL';

export const CHANGE_AUTHEMAIL_REQUEST = 'CHANGE_AUTHEMAIL_REQUEST';
export const CHANGE_AUTHEMAIL_SUCCESS = 'CHANGE_AUTHEMAIL_SUCCESS';
export const CHANGE_AUTHEMAIL_FAILURE = 'CHANGE_AUTHEMAIL_FAILURE';

export const CONFIRM_AUTHEMAIL_REQUEST = 'CONFIRM_AUTHEMAIL_REQUEST';
export const CONFIRM_AUTHEMAIL_SUCCESS = 'CONFIRM_AUTHEMAIL_SUCCESS';
export const CONFIRM_AUTHEMAIL_FAILURE = 'CONFIRM_AUTHEMAIL_FAILURE';

export const emailActions = {
  reset,
  change,
  confirm,
};

function reset() {
  return (dispatch) => {
    dispatch(() => {
      return {
        type: RESET_AUTHEMAIL,
      };
    });
  };
}

function change(newEmail) {
  return (dispatch) => {
    dispatch(request());

    Auth.currentAuthenticatedUser().then(
      (currentUser) => {
        Auth.updateUserAttributes(currentUser, { email: newEmail }).then(
          () => {
            dispatch(success());
          },
          (error) => {
            dispatch(failure(error));
          }
        );
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CHANGE_AUTHEMAIL_REQUEST };
  }
  function success(user) {
    return { type: CHANGE_AUTHEMAIL_SUCCESS };
  }
  function failure(error) {
    return {
      type: CHANGE_AUTHEMAIL_FAILURE,
      payload: error,
    };
  }
}

function confirm(code) {
  return (dispatch) => {
    dispatch(request());

    Auth.verifyCurrentUserAttributeSubmit('email', code).then(
      () => {
        dispatch(success());
        history.push('/');
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CONFIRM_AUTHEMAIL_REQUEST };
  }
  function success() {
    return { type: CONFIRM_AUTHEMAIL_SUCCESS };
  }
  function failure(error) {
    return {
      type: CONFIRM_AUTHEMAIL_FAILURE,
      payload: error,
    };
  }
}
