/* eslint-disable camelcase */
import common_nl from './nl/common.json';
import common_en from './en/common.json';
import common_de from './de/common.json';
import common_fr from './fr/common.json';

export const resources = {
  nl: {
    common: common_nl,
  },
  en: {
    common: common_en,
  },
  de: {
    common: common_de,
  },
  fr: {
    common: common_fr,
  },
};

const defaultApplicationLanguage = () => {
  var lang = navigator.language || navigator.userLanguage;
  if (!lang) {
    return 'en';
  }

  if (lang) {
    var code = lang.substring(0, 2).toLowerCase();
    if (['en', 'de', 'fr', 'nl'].indexOf(code) > -1) {
      return code;
    }
    return 'en';
  }
};

export const config = {
  interpolation: { escapeValue: false }, // React already does escaping
  lng: defaultApplicationLanguage(),
  resources,
};
