import {
  LOGIN_AUTHLOGIN_REQUEST,
  LOGIN_AUTHLOGIN_SUCCESS,
  LOGIN_AUTHLOGIN_FORCEPASSWORDCHANGE,
  LOGIN_AUTHLOGIN_FAILURE,
  VALIDATE_AUTHLOGIN_REQUEST,
  VALIDATE_AUTHLOGIN_SUCCESS,
  VALIDATE_AUTHLOGIN_FAILURE,
} from 'redux/actions/auth/loginActions';

const initialState = {
  username: null,
  role: null,
  isAuthenticated: null,
  isAuthenticating: true,
  forcePasswordChange: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_AUTHLOGIN_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
      };

    case LOGIN_AUTHLOGIN_FAILURE:
      return {
        ...state,
        username: null,
        isAuthenticated: false,
        isAuthenticating: false,
        forcePasswordChange: false,
        error: action.payload.message,
      };

    case LOGIN_AUTHLOGIN_SUCCESS:
      return {
        ...state,
        username: action.payload,
        isAuthenticated: true,
        isAuthenticating: false,
        forcePasswordChange: false,
        error: null,
      };

    case LOGIN_AUTHLOGIN_FORCEPASSWORDCHANGE:
      return {
        ...state,
        username: action.payload,
        isAuthenticated: false,
        isAuthenticating: false,
        forcePasswordChange: true,
        error: null,
      };

    case VALIDATE_AUTHLOGIN_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
      };

    case VALIDATE_AUTHLOGIN_FAILURE:
      return {
        ...state,
        username: null,
        isAuthenticated: false,
        isAuthenticating: false,
        forcePasswordChange: false,
        error: action.payload.message,
      };

    case VALIDATE_AUTHLOGIN_SUCCESS:
      return {
        ...state,
        username: action.payload.name,
        isAuthenticated: true,
        isAuthenticating: false,
        forcePasswordChange: false,
        error: null,
      };

    default:
      return state;
  }
}
