import React from 'react';
import { Statistic } from 'antd';
import { Panel } from 'shared/components';

const Tabs = (props) => {
  return (
    <>
      {props.data.hidePanel && props.data.hidePanel.toUpperCase() === 'X' ? (
        <Statistic title={props.data.title} value={props.data.value} />
      ) : (
        <Panel>
          <Statistic title={props.data.title} value={props.data.value} />
        </Panel>
      )}
    </>
  );
};

export default Tabs;
