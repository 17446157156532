import {
  RESET_AUTHEMAIL,
  CHANGE_AUTHEMAIL_REQUEST,
  CHANGE_AUTHEMAIL_SUCCESS,
  CHANGE_AUTHEMAIL_FAILURE,
  CONFIRM_AUTHEMAIL_REQUEST,
  CONFIRM_AUTHEMAIL_SUCCESS,
  CONFIRM_AUTHEMAIL_FAILURE,
} from 'redux/actions/auth/emailActions';

const initialState = {
  isFetching: false,
  pendingConfirmation: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_AUTHEMAIL:
      return initialState;

    case CHANGE_AUTHEMAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        pendingConfirmation: false,
        error: null,
      };

    case CHANGE_AUTHEMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        pendingConfirmation: false,
        error: action.payload.message,
      };

    case CHANGE_AUTHEMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        pendingConfirmation: true,
        error: null,
      };

    case CONFIRM_AUTHEMAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        pendingConfirmation: true,
        error: null,
      };

    case CONFIRM_AUTHEMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        pendingConfirmation: true,
        error: action.payload.message,
      };

    case CONFIRM_AUTHEMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        pendingConfirmation: false,
        error: null,
      };

    default:
      return state;
  }
}
