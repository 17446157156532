export const SET_PATHS = 'SET_PATHS';

export const pathActions = {
  set,
};

function set(paths) {
  return (dispatch) => {
    dispatch({
      type: 'SET_PATHS',
      payload: {
        paths,
      },
    });
  };
}
