import React from 'react';
import { withRouter } from 'react-router-dom';

const MainWrapper = (props) => {
  return (
    <div className="theme-light ltr-support" dir="ltr">
      <div className="wrapper">{props.children}</div>
    </div>
  );
};

export default withRouter(MainWrapper);
