import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { passwordActions } from 'redux/actions/auth/passwordActions';
import TopbarWithoutLogin from 'shared/components/TopbarWithoutLogin';
import ChangePasswordForm from './components/ChangePasswordForm';

const ChangePassword = (props) => {
  const { dispatch, t, authPassword } = props;

  const onChangePassword = (oldPassword, newPassword) => {
    dispatch(passwordActions.change(oldPassword, newPassword));
  };

  return (
    <>
      <TopbarWithoutLogin />
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">{t('change-password.update-password')}</h3>
            </div>
            <ChangePasswordForm onChangePassword={onChangePassword} errorMessage={authPassword.error} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authPassword: state.auth.password,
});

const ChangePasswordContainer = compose(withTranslation('common'), connect(mapStateToProps))(ChangePassword);

export { ChangePasswordContainer as ChangePassword };
