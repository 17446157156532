import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';

const ChangePasswordForm = (props) => {
  const { onChangePassword, errorMessage, t } = props;
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [localError, setLocalError] = useState('');

  const onChangeInputNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const onChangeInputConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const changePassword = (newPassword, confirmPassword) => {
    if (newPassword === '') {
      setLocalError(t('login.password-empty'));
      return;
    }
    if (newPassword !== confirmPassword) {
      setLocalError(t('login.password-mismatch'));
      return;
    }
    setLocalError('');

    onChangePassword(newPassword);
  };

  const onClickButtonChangePassword = () => {
    changePassword(newPassword, confirmPassword);
  };

  const onKeyDownInputConfirmPassword = (event) => {
    if (event.key === 'Enter') {
      changePassword(newPassword, confirmPassword);
    }
  };

  return (
    <Form className="form">
      <Alert color="danger" style={{ color: 'white', marginTop: -24, marginBottom: 20 }} isOpen={(errorMessage && errorMessage !== '') || localError !== ''}>
        {localError !== '' ? localError : errorMessage}
      </Alert>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-new_password">
          {t('login.new-password')}
        </Label>
        <Input
          type="password"
          name="new_password"
          id="form-input-new_password"
          placeholder={t('login.new-password-placeholder')}
          value={newPassword}
          onChange={onChangeInputNewPassword}
        />
      </FormGroup>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-confirm_password">
          {t('login.confirm-password')}
        </Label>
        <Input
          type="password"
          name="confirm_password"
          id="form-input-confirm_password"
          placeholder={t('login.confirm-password-placeholder')}
          value={confirmPassword}
          onChange={onChangeInputConfirmPassword}
          onKeyDown={onKeyDownInputConfirmPassword}
        />
      </FormGroup>
      <hr style={{ width: '100%' }} />
      <Button className="account__btn" type="button" onClick={onClickButtonChangePassword} color="primary">
        {t('login.confirm')}
      </Button>
    </Form>
  );
};

export default withTranslation('common')(ChangePasswordForm);
