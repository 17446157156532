import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { querystring } from 'utils';
import history from 'containers/App/history';
import { withTranslation } from 'react-i18next';
import { userRoleActions } from 'redux/actions/user/userRoleActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from 'redux/actions/app/appSidebarActions';
import { loginActions } from 'redux/actions/auth/loginActions';
import Topbar from 'containers/Layout/components/topbar/Topbar';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { Result, Button } from 'antd';

const UserRoleSelect = (props) => {
  const { t, dispatch, authLogin, userRole } = props;

  useEffect(() => {
    let redirect = querystring('redirect');
    if (redirect === '/not_found') {
      redirect = '';
    }
    if (!authLogin.isAuthenticated || userRole.selection) {
      history.push('/session' + (redirect ? '?redirect=' + redirect : ''));
    }

    dispatch(userRoleActions.get());
  }, [authLogin.isAuthenticated, userRole.selection, dispatch]);

  const onClickRole = (role) => {
    dispatch(userRoleActions.set(role));
    // const redirect = querystring('redirect');
    // if (!authLogin.isAuthenticated || userRole.selection) {
    //   history.push('/session' + (redirect ? '?redirect=' + redirect : ''));
    // }
  };

  const handleChangeSidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const handleChangeMobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const handleLogout = () => {
    dispatch(loginActions.logOut());
  };

  const handleChangeRole = () => {
    localStorage.removeItem("portalRole");
    dispatch({ type: 'CHANGE_ROLE' });
    history.push('/');
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleTryAgain = () => {
    dispatch(userRoleActions.get());
  };

  let component = (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 60, left: 0, right: 0, bottom: 0 }}>
      <div>
        <div className="panel__refresh" style={{ backgroundColor: 'transparent' }}>
          <LoadingIcon />
        </div>
      </div>
      <div style={{ zIndex: 10000, marginTop: 90, color: '#666', textAlign: 'center', width: '100%' }}>{t('select-user-role.fetching-data')}</div>
    </div>
  );
  if (userRole.list.error) {
    component = (
      <Result
        style={{ marginTop: 60 }}
        status="500"
        title={userRole.list.error.code + ''}
        subTitle={userRole.list.error.message}
        extra={
          <>
            <Button type="link" onClick={handleTryAgain}>
              {t('select-user-role.try-again')}
            </Button>
            <Button type="link" onClick={handleLogout}>
              {t('select-user-role.logout')}
            </Button>
          </>
        }
      />
    );
  } else if (userRole.list.data) {
    if (userRole.list.data.length) {
      component = (
        <div className="account">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="account__head">
                <h3 className="account__title">{t('select-user-role.role')}</h3>
                <h4 className="account__subhead subhead">{t('select-user-role.choose_role')}</h4>
              </div>
              <div className="account__role">
                {userRole.list.data.map((role, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        onClickRole(role);
                      }}
                    >
                      <span>{role.roleName}</span>
                      <br />
                      <span>
                        {role.sapName} ({role.sapId})
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (userRole.list.data.length === 0) {
      component = (
        <div className="account">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="account__head">
                <h3 className="account__title">{t('select-user-role.role')}</h3>
                <h4 className="account__subhead subhead">{t('select-user-role.no-roles-assigned')}</h4>
              </div>
              <div className="account__role">
                <>
                  <Button onClick={handleLogout}>{t('select-user-role.logout')}</Button>
                </>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <Topbar
        username={authLogin.username}
        role={userRole}
        changeMobileSidebarVisibility={handleChangeMobileSidebarVisibility}
        changeSidebarVisibility={handleChangeSidebarVisibility}
        changeRole={handleChangeRole}
        changeLanguage={handleChangeLanguage}
        logout={handleLogout}
      />
      {component}
    </div>
  );
};

const mapStateToProps = (state) => ({
  authLogin: state.auth.login,
  userRole: state.user.role,
});

const UserRoleSelectContainer = compose(withTranslation('common'), connect(mapStateToProps))(UserRoleSelect);

export { UserRoleSelectContainer as UserRoleSelect };
