import React from 'react';
import { Tabs as SharedTabs } from 'shared/components';

const Tabs = (props) => {
  const items = props.data && props.data.items ? props.data.items : [];
  const selectedKey = props.data ? props.data.selectedKey : null;

  return <SharedTabs items={items} selectedKey={selectedKey} />;
};

export default Tabs;
