export const SET_MENU_ITEMS = 'SET_MENU_ITEMS';

export const menuListActions = {
  setItems,
};

function setItems(menuItems) {
  return (dispatch) => {
    dispatch({
      type: 'SET_MENU',
      payload: {
        menuItems,
      },
    });
  };
}
