import { getData } from 'utils';
import { SET_PATHS } from 'redux/actions/pages/pathActions';
import { SET_MENU_ITEMS } from 'redux/actions/menu/menuActions';

export const GET_USERROLE_REQUEST = 'GET_USERROLE_REQUEST';
export const GET_USERROLE_SUCCESS = 'GET_USERROLE_SUCCESS';
export const GET_USERROLE_FAILURE = 'GET_USERROLE_FAILURE';
export const SET_USERROLE = 'SET_USERROLE';

export const userRoleActions = {
  get,
  set,
};

function set(role) {
  return (dispatch) => {
    localStorage.setItem("portalRole", JSON.stringify(role));

    dispatch({
      type: SET_PATHS,
      payload: {
        pages: role.pages || [],
      },
    });

    dispatch({
      type: SET_MENU_ITEMS,
      payload: {
        menuItems: role.menu || [],
      },
    });

    dispatch(setRole(role));
  };

  function setRole(role) {
    return {
      type: SET_USERROLE,
      payload: {
        data: {
          role,
        },
      },
    };
  }
}

function get() {
  return (dispatch, getState) => {
    if (getState().user.role.selection || getState().user.role.list.isFetching) {
      return;
    }

    const localStorageRole = localStorage.getItem("portalRole");
    if (localStorageRole) {
      dispatch(set(JSON.parse(localStorageRole)));
      return;
    }

    dispatch(request());

    getData('USERROLES').then(
      (data) => dispatch(success(data)),
      (error) => {
        dispatch(failure(error.response.data));
      }
    );

    function request() {
      return {
        type: GET_USERROLE_REQUEST,
      };
    }
    function failure(error) {
      return {
        type: GET_USERROLE_FAILURE,
        payload: {
          error,
        },
      };
    }
    function success(data) {
      if (data && data.roleList && data.roleList.length && data.roleList.length === 1) {
        dispatch(set(data.roleList[0]));
      }

      return {
        type: GET_USERROLE_SUCCESS,
        payload: {
          data: data.roleList,
        },
      };
    }
  };
}
