import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { querystring } from 'utils';
import { loginActions } from 'redux/actions/auth/loginActions';
import history from 'containers/App/history';
import LoadingIcon from 'mdi-react/LoadingIcon';

const Session = (props) => {
  const { dispatch, authLogin, menu } = props;

  useEffect(() => {
    const redirect = querystring('redirect');

    switch (authLogin.isAuthenticated) {
      case null:
        dispatch(loginActions.validate());
        break;
      case false:
        history.push('/auth/login' + (redirect ? '?redirect=' + redirect : ''));
        break;
      case true:
        if (redirect) {
          history.push(redirect);
        } else if (menu.items && menu.items.length) {
          history.push(menu.items[0].path);
        } else {
          history.push('/auth/roles');
        }
        break;
      default:
    }
  }, [authLogin.isAuthenticated, menu.items, dispatch]);

  return (
    <div className="session__refresh">
      <LoadingIcon />
    </div>
  );
};

const mapStateToProps = (state) => ({
  authLogin: state.auth.login,
  menu: state.menu,
});

const SessionContainer = connect(mapStateToProps)(Session);

export { SessionContainer as Session };
