import React from 'react';
import { DatePicker as AntDatepicker } from 'antd';
import 'moment/locale/nl';
import locale from 'antd/es/date-picker/locale/nl_NL';

const Datepicker = (props) => {
  return <AntDatepicker {...props} locale={locale} format={'YYYY-MM-DD'} />;
};

export default Datepicker;
