import React, { useEffect } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { passwordActions } from 'redux/actions/auth/passwordActions';
import history from 'containers/App/history';
import ResetPasswordForm from './components/ResetPasswordForm';
import TopbarWithoutLogin from 'shared/components/TopbarWithoutLogin';

const ResetPassword = (props) => {
  const { t, dispatch, authPassword } = props;

  useEffect(() => {
    dispatch(passwordActions.reset());
  }, [dispatch]);

  const onResetPassword = (email) => {
    dispatch(passwordActions.resetPassword(email));
  };

  const onConfirmation = (email, code, password) => {
    dispatch(passwordActions.confirm(email, code, password));
  };

  const onCancel = () => {
    dispatch(passwordActions.reset());
    history.push('/');
  };

  return (
    <>
      <TopbarWithoutLogin />
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">{t('reset-password.reset-password')}</h3>
            </div>
            <div style={{ fontSize: 12, marginBottom: 25 }}>{t('reset-password.description')}</div>
            <ResetPasswordForm
              pendingConfirmation={authPassword.pendingConfirmation}
              errorMessage={authPassword.error}
              onResetPassword={onResetPassword}
              onConfirmation={onConfirmation}
              onCancel={onCancel}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authPassword: state.auth.password,
});

const ResetPasswordContainer = compose(withTranslation('common'), connect(mapStateToProps))(ResetPassword);

export { ResetPasswordContainer as ResetPassword };
