import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const Back = (props) => {
  const { t, history } = props;

  const onClickBack = () => {
    history.goBack();
  };

  return (
    <div className="jbi-back-wrapper">
      <div className="jbi-back" onClick={onClickBack}>
        <span>{t('back')}</span>
      </div>
    </div>
  );
};

export default withTranslation('common')(withRouter(Back));
