import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { loginActions } from 'redux/actions/auth/loginActions';
import { passwordActions } from 'redux/actions/auth/passwordActions';
import TopbarWithoutLogin from 'shared/components/TopbarWithoutLogin';
import LogInForm from './components/LogInForm';
import ForcePasswordChangeForm from './components/ForcePasswordChangeForm';

const Login = (props) => {
  const { t, dispatch, authLogin, authPassword } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const onLogin = (email, password, rememberMe) => {
    dispatch(loginActions.logIn(email, password, rememberMe));
  };

  const onChangePassword = (newPassword) => {
    dispatch(passwordActions.changeRequired(email, password, newPassword, rememberMe));
  };

  const displayComponent = authLogin.forcePasswordChange ? (
    <ForcePasswordChangeForm onChangePassword={onChangePassword} errorMessage={authPassword.error} />
  ) : (
    <LogInForm
      onLogin={onLogin}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      rememberMe={rememberMe}
      setRememberMe={setRememberMe}
      errorMessage={authLogin.error}
    />
  );

  return (
    <>
      <TopbarWithoutLogin />
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">
                {t('login.welcome')}
                <span className="account__logo pl-1">{t('login.application')}</span>
              </h3>
              <h4 className="account__subhead subhead">{authLogin.forcePasswordChange ? t('login.force-change-password') : t('login.subtitle')}</h4>
            </div>
            {displayComponent}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authLogin: state.auth.login,
  authPassword: state.auth.password,
});

const LoginContainer = compose(withTranslation('common'), connect(mapStateToProps))(Login);

export { LoginContainer as Login };
