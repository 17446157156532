import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'scss/app.scss';
import { AppRouter } from 'routes';
import ScrollToTop from './ScrollToTop';
import history from './history';
import store from 'redux/store';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { config as i18nextConfig } from 'translations';

i18next.init(i18nextConfig);

const App = () => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <Router history={history}>
          <ScrollToTop>
            <Fragment>
              <AppRouter />
            </Fragment>
          </ScrollToTop>
        </Router>
      </I18nextProvider>
    </Provider>
  );
};

export { App };
