import { SET_MENU_ITEMS } from 'redux/actions/menu/menuActions';

const initialState = {
  selected: null,
  items: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MENU_ITEMS:
      return {
        selected: action.payload.menuItems.length ? action.payload.menuItems[0] : null,
        items: action.payload.menuItems,
      };

    default:
      return state;
  }
}
