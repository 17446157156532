import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { emailActions } from 'redux/actions/auth/emailActions';
import TopbarWithoutLogin from 'shared/components/TopbarWithoutLogin';
import ChangeEmailForm from './components/ChangeEmailForm';

const ChangeEmail = (props) => {
  const { dispatch, authEmail, t } = props;

  useEffect(() => {
    dispatch(emailActions.reset());
  }, [dispatch]);

  const onChangeEmail = (newEmail) => {
    dispatch(emailActions.change(newEmail));
  };

  const onConfirmation = (code) => {
    dispatch(emailActions.confirm(code));
  };

  return (
    <>
      <TopbarWithoutLogin />
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">{t('change-email.change-email')}</h3>
            </div>
            <div style={{ fontSize: 12, marginBottom: 25 }}>{t('change-email.description')}</div>
            <ChangeEmailForm onChangeEmail={onChangeEmail} onConfirmation={onConfirmation} pendingConfirmation={authEmail.pendingConfirmation} errorMessage={authEmail.error} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authEmail: state.auth.email,
});

const ChangeEmailContainer = compose(withTranslation('common'), connect(mapStateToProps))(ChangeEmail);

export { ChangeEmailContainer as ChangeEmail };
