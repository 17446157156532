import React from 'react';
import { Panel } from 'shared/components';

import Highcharts from 'highcharts';

import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';

const Chart = (props) => {
  HighchartsMore(Highcharts);

  const cfg = props.data;
  const chartOptions = {
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
  };

  if (cfg) {
    if (cfg.subttitle !== '') {
      chartOptions.subtitle = {
        text: cfg.subtitle,
      };
    }

    if (cfg.zoomType !== '') {
      chartOptions.chart = chartOptions.chart || {};
      chartOptions.chart.zoomType = cfg.zoomType;
    }

    chartOptions.tooltip = {
      enabled: cfg.tooltip.toUpperCase() === 'X',
      shared: true,
      crosshairs: true,
      useHtml: true,
      formatter: function (tooltip) {
        if (this.point && this.point.tooltipLabel && this.point.tooltipLabel !== '') {
          return this.point.tooltipLabel;
        }
        // If not null, use the default formatter
        return tooltip.defaultFormatter.call(this, tooltip);
      },
    };

    chartOptions.legend = {
      enabled: cfg.legend.toUpperCase() === 'X',
    };

    // if timeSeries are provided, the xAxis type will be set to datetime and the categories will be ignored
    if (cfg.timeseries === 'X') {
      chartOptions.xAxis = {
        title: cfg.xAxis.title === '' ? null : cfg.xAxis.title,
        type: 'datetime',
      };

      if (cfg.xAxis.plotbands.length) {
        chartOptions.xAxis.plotBands = cfg.xAxis.plotbands.map((item, index) => {
          return {
            from: Date.parse(item.from),
            to: Date.parse(item.to),
            color: item.color,
            label: {
              text: item.label,
              rotation: 90,
              align: 'left',
            },
          };
        });
      }
    } else {
      chartOptions.xAxis = chartOptions.xAxis || [{}];
      chartOptions.xAxis[0].title = {
        text: cfg.xAxis.title === '' ? null : cfg.xAxis.title,
      };
      if (cfg.xAxis.categories.length) {
        chartOptions.xAxis[0].categories = cfg.xAxis.categories;
      }
    }

    if (!cfg.yAxis.length) {
      chartOptions.yAxis = chartOptions.yAxis || [
        {
          gridLineWidth: 0,
        },
      ];
    } else {
      chartOptions.yAxis = cfg.yAxis.map((item) => {
        const obj = {
          title: {
            text: item.title ? item.title : null,
          },
          opposite: item.opposite === 'X',
          gridLineWidth: 0,
        };
        if (item.min !== '') {
          try {
            obj.min = parseFloat(item.min);
          } catch (e) {}
        }
        if (item.max !== '') {
          try {
            obj.max = parseFloat(item.max);
          } catch (e) {}
        }
        return obj;
      });
    }

    if (cfg.series.length) {
      chartOptions.series = cfg.series.map((serie) => {
        const chartSerie = {};
        if (serie.name !== '') {
          chartSerie.name = serie.name;
        }
        if (serie.datalabels.toUpperCase() === 'X') {
          chartSerie.dataLabels = {
            enabled: true,
          };
        }
        if (serie.color !== '') {
          chartSerie.color = serie.color;
        }
        if (serie.type !== '') {
          chartSerie.type = serie.type;
        }
        if (serie.yAxis !== '') {
          try {
            chartSerie.yAxis = parseInt(serie.yAxis);
          } catch (e) {}
        }
        if (serie.dashStyle !== '') {
          chartSerie.dashStyle = serie.dashStyle;
        }
        if (serie.marker === '') {
          chartSerie.marker = {
            enabled: false,
          };
        } else {
          chartSerie.marker = {
            enabled: true,
            symbol: serie.marker,
          };
        }

        if (serie.data.length) {
          chartSerie.data = serie.data.map((value) => {
            if (value === 'null' || value === '') {
              return null;
            }
            try {
              return parseFloat(value);
            } catch (e) {
              return null;
            }
          });
        }

        if (serie.dataArray.length) {
          chartSerie.data = serie.dataArray.map((valueArray) => {
            return valueArray.map((value, index) => {
              if (value === 'null' || value === '') {
                return null;
              }
              try {
                if (cfg.timeseries === 'X' && index === 0) {
                  return Date.parse(value);
                } else {
                  return parseFloat(value);
                }
              } catch (e) {
                return null;
              }
            });
          });
        }

        if (serie.dataPoints.length) {
          chartSerie.data = serie.dataPoints.map((item) => {
            const point = {};

            if (item.x === 'null' || item.x === '') {
              point.x = null;
            }
            try {
              if (cfg.timeseries === 'X') {
                point.x = Date.parse(item.x);
              } else {
                point.x = item.x;
              }
            } catch (e) {
              point.x = null;
            }

            if (item.y === 'null' || item.y === '') {
              point.y = null;
            }
            try {
              point.y = parseFloat(item.y);
            } catch (e) {
              point.y = null;
            }

            if (item.color !== '') {
              point.color = item.color;
            }

            if (item.label !== '') {
              point.dataLabels = {
                allowOverlap: true,
                formatter: () => {
                  return item.label;
                },
              };
            }

            if (item.tooltipLabel !== '') {
              point.tooltipLabel = item.tooltipLabel;
            }

            return point;
          });
        }

        return chartSerie;
      });
    }
  }

  return (
    <Panel title={cfg && cfg.title && cfg.title !== '' ? cfg.title : null} titleNav={cfg.titleNavigationPath && cfg.titleNavigationPath !== '' ? cfg.titleNavigationPath : null}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Panel>
  );
};

export default Chart;
