import {
  GET_MAP_ESRITOKEN_REQUEST,
  GET_MAP_ESRITOKEN_FAILURE,
  GET_MAP_ESRITOKEN_SUCCESS,
} from "redux/actions/map/mapActions";

const initialState = {
  esriToken: null,
};

export default function (state = initialState, action) {
  let map;

  switch (action.type) {
    case GET_MAP_ESRITOKEN_REQUEST:
      map = { ...state };
      return map;

    case GET_MAP_ESRITOKEN_SUCCESS:
      map = { ...state };
      map.esriToken = action.payload.esriToken;
      return map;

    case GET_MAP_ESRITOKEN_FAILURE:
      map = { ...state };
      return map;

    default:
      return state;
  }
}
