import React, { useRef, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import L from "leaflet";
import * as esri from "esri-leaflet";
import MarkerIconRed from "./../img/leaflet-marker/marker-icon-red.png";
import MarkerIconGreen from "./../img/leaflet-marker/marker-icon-green.png";
import MarkerShadow from "./../img/leaflet-marker/marker-shadow.png";
import history from "containers/App/history";
import { pageActions } from "redux/actions/pages/pageActions";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";

const LeafletMap = (props) => {
  const dispatch = props.dispatch;
  const settings = props.settings || {};
  const customId = props.customId || "cropDetailsLocationMap";
  const esriToken = props.esriToken || "";

  const [modalDrawText, setModalDrawText] = useState(false);
  const [drawItemText, setDrawItemText] = useState("");
  const [drawItem, setDrawItem] = useState(null);

  /*
    Update the map in case the settings change
  */
  const mapRef = useRef(null);
  useEffect(() => {
    if (mapRef.current !== null) {
      mapRef.current.remove();
    }

    /**
     instantiate the map
    */
    mapRef.current = L.map(customId, {
      center: settings.center || [52.0, 4.17],
      zoom: settings.zoom || 10,
      attributionControl: false,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: "topleft",
      },
    });

    const onEachFeature = (feature, layer) => {
      // direct link
      if (feature.navigationPath !== "") {
        layer.on({
          click: () => {
            history.push(feature.navigationPath);
          },
        });
      }

      // HTML based popup
      if (feature.htmlPopup !== "") {
        // in case a navigationPath is linked to the feature, use the mouseover event for showing
        // the popup. Else, use the click event to show the popup
        layer.bindPopup(feature.htmlPopup);
        if (feature.navigationPath === "") {
          layer.openPopup();
        } else {
          layer.on("mouseover", function () {
            layer.openPopup();
          });
          layer.on("mouseout", function () {
            layer.closePopup();
          });
        }
      }

      // Popup based on properties
      else if (feature.properties) {
        const properties = Object.keys(feature.properties);
        if (properties.length) {
          layer.bindPopup(
            properties
              .map((property) => {
                return `<b>${property}: </b> ${feature.properties[property]}`;
              })
              .join("<br>")
          );

          // in case a navigationPath is linked to the feature, use the mouseover event for showing
          // the popup. Else, use the click event to show the popup
          if (feature.navigationPath === "") {
            layer.openPopup();
          } else {
            layer.on("mouseover", function () {
              layer.openPopup();
            });
            layer.on("mouseout", function () {
              layer.closePopup();
            });
          }
        }
      }
    };


    // geoJson
    if (settings.geoJson && settings.geoJson.features.length) {
      const gLayer = L.geoJson(settings.geoJson, { onEachFeature }).addTo(
        mapRef.current
      );

      // add a marker in the middle
      // var greenIcon = new L.Icon({
      //   iconUrl: MarkerIconGreen,
      //   shadowUrl: MarkerShadow,
      //   iconSize: [25, 41],
      //   iconAnchor: [12, 41],
      //   popupAnchor: [1, -34],
      //   shadowSize: [41, 41],
      // });

      // const centerGeoJson = gLayer.getBounds().getCenter();
      // L.marker(centerGeoJson, { icon: greenIcon }).addTo(mapRef.current);

      mapRef.current.fitBounds(gLayer.getBounds());
    }

    // Add a marker on the current location
    let currentLocationMarker = null;
    let currentLocation = null;
    const greenIcon = new L.Icon({
      iconUrl: MarkerIconGreen,
      shadowUrl: MarkerShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });

    mapRef.current.locate({ watch: true }).on("locationfound", function (e) {
      currentLocation = e.latlng;

      if (currentLocationMarker) {
        mapRef.current.removeLayer(currentLocationMarker);
      }
      currentLocationMarker = L.marker(e.latlng, {
        icon: greenIcon,
      })
        .bindPopup(
          "Your coordinate is: <br>Latitude: " +
            e.latitude +
            "<br> Longitude: " +
            e.longitude +
            "<br>Accuracy: " +
            e.accuracy
        )
        .addTo(mapRef.current);

      currentLocationMarker.on("mouseover", function () {
        currentLocationMarker.openPopup();
      });
      currentLocationMarker.on("mouseout", function () {
        currentLocationMarker.closePopup();
      });
    });

    /**
     * Draw on Map
     */
    let currentItems = [];

    const redIcon = new L.Icon({
      iconUrl: MarkerIconRed,
      shadowUrl: MarkerShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });

    if (
      settings &&
      settings.draw &&
      settings.draw.config &&
      settings.draw.config.enabled === "X"
    ) {
      let drawnItems = new L.FeatureGroup();
      mapRef.current.addLayer(drawnItems);
      let drawControl = new L.Control.Draw({
        position: "bottomleft",
        draw: {
          polyline:
            settings.draw.config.drawItems.polyline === "X"
              ? { shapeOptions: { color: "red" } }
              : false,
          polygon:
            settings.draw.config.drawItems.polygon === "X"
              ? {
                  allowIntersection: false,
                  shapeOptions: {
                    color: "red",
                  },
                }
              : false,
          marker:
            settings.draw.config.drawItems.marker === "X"
              ? { icon: redIcon }
              : false,
          rectangle:
            settings.draw.config.drawItems.rectangle === "X"
              ? { shapeOptions: { color: "red" } }
              : false,
          circle:
            settings.draw.config.drawItems.circle === "X"
              ? { shapeOptions: { color: "red" } }
              : false,
          circlemarker:
            settings.draw.config.drawItems.circlemarker === "X"
              ? { fillColor: "red", color: "red" }
              : false,
        },
        edit: {
          featureGroup: drawnItems,
        },
      });
      mapRef.current.addControl(drawControl);

      if (
        settings &&
        settings.draw &&
        settings.draw.features &&
        settings.draw.features.length
      ) {
        settings.draw.features.forEach((featureItem) => {
          try {
            const geometry = JSON.parse(featureItem.geometry);
            const popupText = featureItem.text;
            addGeometryToDrawLayer(geometry, popupText);
          } catch (e) {
            console.error(e);
          }
        });
      }

      // Events
      mapRef.current.on(L.Draw.Event.CREATED, function (e) {
        drawnItems.addLayer(e.layer);
      });

      mapRef.current.on(L.Draw.Event.DRAWSTART, function (e) {
        if (settings.draw.config.multiple !== "X") {
          currentItems = getDrawnFeatures();
          drawnItems.clearLayers();
        }
      });

      mapRef.current.on(L.Draw.Event.DRAWSTOP, function (e) {
        const layers = drawnItems.getLayers();

        // on canceled with single item, revert to previous geometry
        if (layers.length === 0 && settings.draw.config.multiple !== "X") {
          currentItems.forEach((geometry) => {
            try {
              addGeometryToDrawLayer(geometry);
            } catch (e) {}
          });
        } else {
          // add the double click event for the newly added item
          if (
            settings &&
            settings.draw &&
            settings.draw.config &&
            settings.draw.config.allowText &&
            settings.draw.config.allowText === "X"
          ) {
            layers.forEach((layer) => {
              if (!layer._events["dblclick"]) {
                layer.bindPopup("");
                layer.on("dblclick", (evt) => {
                  setDrawItem(evt.target);
                  setDrawItemText(evt.target.options.popupText);
                  setModalDrawText(true);
                  return false;
                });
              }
            });
          }
        }
        saveGeometry();
      });

      mapRef.current.on(L.Draw.Event.EDITSTOP, function (e) {
        saveGeometry();
      });

      mapRef.current.on(L.Draw.Event.DELETESTOP, function (e) {
        saveGeometry();
      });

      // Helper functions
      function addGeometryToDrawLayer(geometry, popupText) {
        try {
          if (geometry.type === "Point") {
            if (geometry.coordinates && geometry.coordinates.length === 2) {
              const marker = L.marker(
                [geometry.coordinates[1], geometry.coordinates[0]],
                {
                  icon: redIcon,
                  popupText,
                }
              );
              if (popupText && popupText !== "") {
                marker.bindPopup(popupText);
              }
              if (
                settings &&
                settings.draw &&
                settings.draw.config &&
                settings.draw.config.allowText &&
                settings.draw.config.allowText === "X"
              ) {
                marker.on("dblclick", (evt) => {
                  setDrawItem(evt.target);
                  setDrawItemText(evt.target.options.popupText);
                  setModalDrawText(true);
                });
              }
              drawnItems.addLayer(marker);
            }
          } else if (geometry.type === "Polygon") {
            const latlon = [];
            for (let i = 0; i < geometry.coordinates[0].length; i++) {
              const coor = geometry.coordinates[0][i];
              latlon.push([coor[1], coor[0]]);
            }
            const polygon = L.polygon(latlon, {
              fillColor: "red",
              color: "red",
              popupText,
            });
            if (popupText && popupText !== "") {
              polygon.bindPopup(popupText);
            }
            if (
              settings &&
              settings.draw &&
              settings.draw.config &&
              settings.draw.config.allowText &&
              settings.draw.config.allowText === "X"
            ) {
              polygon.on("dblclick", (evt) => {
                setDrawItem(evt.target);
                setDrawItemText(evt.target.options.popupText);
                setModalDrawText(true);
              });
            }
            drawnItems.addLayer(polygon);
          } else if (geometry.type === "LineString") {
            const latlon = [];
            for (let i = 0; i < geometry.coordinates.length; i++) {
              const coor = geometry.coordinates[i];
              latlon.push([coor[1], coor[0]]);
            }
            const polyline = L.polyline(latlon, { color: "red", popupText });
            if (
              settings &&
              settings.draw &&
              settings.draw.config &&
              settings.draw.config.allowText &&
              settings.draw.config.allowText === "X"
            ) {
              polyline.on("dblclick", (evt) => {
                setDrawItem(evt.target);
                setDrawItemText(evt.target.options.popupText);
                setModalDrawText(true);
              });
            }
            if (popupText && popupText !== "") {
              polyline.bindPopup(popupText);
            }
            drawnItems.addLayer(polyline);
          } else if (geometry.type === "MultiPolygon") {
            // not supported
          } else {
            // not supported
          }
        } catch (e) {
          console.error(e);
        }
      }

      const getDrawnFeatures = () => {
        const layers = drawnItems.getLayers();

        let features = [];

        if (layers && layers.length) {
          features = layers.map((layer) => {
            return {
              geometry: layer.toGeoJSON().geometry,
              text: layer.options.popupText,
            };
          });
        }
        return features;
      };

      function saveGeometry() {
        if (settings.draw.config.api.method !== "") {
          const features = getDrawnFeatures();
          const mapContext = settings.draw.config.api.context.map((item) => {
            return {
              NAME: item.name,
              VALUE: item.value,
            };
          });

          dispatch(
            pageActions.mapDrawSubmit({
              mapApi: settings.draw.config.api.method,
              mapContext,
              mapData: features,
            })
          );
        }
      }
    }

    // Base Layers
    // -> from server
    let baselayers = null;
    let overlays = null;
    if (settings.layers && settings.layers.length) {
      baselayers = settings.layers
        .filter((l) => l.type === "B")
        .reduce((acc, obj) => {
          acc[obj.name] = createLeafletLayer(obj);
          return acc;
        }, {});

      let defaultBaselayer = settings.layers.find(
        (layer) => layer.type === "B" && layer.defaultSelected === "X"
      );
      baselayers[defaultBaselayer.name].addTo(mapRef.current);

      overlays = settings.layers
        .filter((l) => l.type === "O")
        .reduce((acc, obj) => {
          acc[obj.name] = createLeafletLayer(obj);
          return acc;
        }, {});

      L.control.layers(baselayers, overlays).addTo(mapRef.current);
    }

    // -> hard-coded in case no baselayers are provided
    if (!baselayers) {
      baselayers = {
        Imagery: esri.basemapLayer("Imagery"),
        "Open Streetmap": L.tileLayer(
          "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          {
            maxZoom: 19,
          }
        ),
      };
      baselayers["Open Streetmap"].addTo(mapRef.current);
      L.control.layers(baselayers).addTo(mapRef.current);
    }

    // add tooltip
    L.Popup.options = {
      autoClose: true,
      closeButon: false,
    };


    // navigate to the current location after clicking the button
    L.easyButton("fa-globe", function (btn, map) {
      if (currentLocation) {
        mapRef.current.flyTo(currentLocation, map.getZoom());
      }
    }).addTo(mapRef.current);

    function createLeafletLayer(params) {
      let lOptions = {};

      // parse the configuration
      if (params.config && params.config !== "") {
        try {
          lOptions = JSON.parse(params.config);
        } catch (e) {
          // invalid options
        }
      }

      switch (params.leafletClass.toLowerCase()) {
        case "esri.basemaplayer":
          return esri.basemapLayer(params.url);

        case "tilelayer":
          return L.tileLayer(params.url, params.config);

        case "esri.tiledmaplayer":
        case "esri.tvs.tiledmaplayer":
          lOptions.url = params.url;
          if (params.leafletClass.toLowerCase() === "esri.tvs.tiledmaplayer") {
            lOptions.token = esriToken;
          }
          return L.esri.tiledMapLayer(lOptions);

        case "tilelayer.wms":
          return L.tileLayer.betterWms(params.url, lOptions);

        case "esri.dynamicmaplayer":
        case "esri.tvs.dynamicmaplayer":
          lOptions.url = params.url;
          if (params.leafletClass === "esri.tvs.dynamicmaplayer") {
            lOptions.token = esriToken;
          }
          var dynamicMapLayer = esri.dynamicMapLayer(lOptions);
          dynamicMapLayer.bindPopup(function (error, featureCollection) {
            var f =
              featureCollection &&
              featureCollection.features &&
              featureCollection.features.length
                ? featureCollection.features[0]
                : null;
            if (!f) {
              return false;
            }
            const props = Object.keys(f.properties);
            const html = props
              .map((prop) => {
                return `<b>${prop}</b>: ${f.properties[prop]}`;
              })
              .join("<br />");

            return html;
          });
          return dynamicMapLayer;

        case "esri.imagemaplayer":
        case "esri.tvs.imagemaplayer":
          lOptions.url = params.url;
          if (params.leafletClass.toLowerCase() === "esri.tvs.imagemaplayer") {
            lOptions.token = esriToken;
          }
          return esri.imageMapLayer(lOptions);

        case "esri.featurelayer":
        case "esri.tvs.featurelayer":
          lOptions.url = params.url;
          if (params.leafletClass.toLowerCase() === "esri.tvs.featurelayer") {
            lOptions.token = esriToken;
          }
          lOptions.onEachFeature = function (feature, layer) {
            if (feature.properties) {
              const props = Object.keys(feature.properties);
              const html = props
                .map((prop) => {
                  return `<b>${prop}</b>: ${feature.properties[prop]}`;
                })
                .join("<br />");
              layer.bindTooltip(html, {
                sticky: true,
              });
            }
          };

          return esri.featureLayer(lOptions);
        default:
          return null;
      }
    }
  }, [settings, customId, esriToken, dispatch]);
  /*
    Setup the map
  */

  const toggle = () => {
    setModalDrawText(!modalDrawText);
  };

  const saveDrawItemText = (e) => {
    drawItem.options.popupText = drawItemText;
    drawItem._popup.setContent(drawItemText);
    mapRef.current.fire(L.Draw.Event.DRAWSTOP);
    setModalDrawText(false);
    setDrawItemText("");
  };

  return (
    <>
      <div
        id={customId}
        style={{ width: "100%", height: "100%", minHeight: 400, margin: 0 }}
      ></div>
      <Modal
        getContainer={`#${customId}`}
        style={{ marginTop: 200 }}
        isOpen={modalDrawText}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Information</ModalHeader>
        <ModalBody style={{ padding: 5 }}>
          <Input
            type="textarea"
            name="text"
            value={drawItemText}
            onChange={(e) => setDrawItemText(e.target.value)}
            style={{ border: 1, backgroundColor: "white", padding: 5 }}
          />
        </ModalBody>
        <ModalFooter style={{ paddingBottom: 0 }}>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={saveDrawItemText}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const Map = connect()(withTranslation("common")(LeafletMap));

export default Map;
