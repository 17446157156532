import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import history from 'containers/App/history';
import classNames from 'classnames';
import Topbar from './components/topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';
import i18n from 'i18next';
import { loginActions } from 'redux/actions/auth/loginActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from 'redux/actions/app/appSidebarActions';

const Layout = (props) => {
  const { dispatch, sidebar, authLogin, userRole, menu } = props;

  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
  });

  const handleChangeSidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const handleChangeMobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const handleLogout = () => {
    dispatch(loginActions.logOut());
  };

  const handleChangeRole = () => {
    localStorage.removeItem("portalRole");
    dispatch({ type: 'CHANGE_ROLE' });
    history.push('/');
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className={layoutClass}>
      <Topbar
        username={authLogin.username}
        role={userRole}
        changeMobileSidebarVisibility={handleChangeMobileSidebarVisibility}
        changeSidebarVisibility={handleChangeSidebarVisibility}
        changeRole={handleChangeRole}
        changeLanguage={handleChangeLanguage}
        logout={handleLogout}
      />
      <Sidebar menuItems={menu.items} sidebar={sidebar} changeMobileSidebarVisibility={handleChangeMobileSidebarVisibility} />
    </div>
  );
};

const LayoutContainer = withRouter(
  connect((state) => ({
    sidebar: state.app.sidebar,
    authLogin: state.auth.login,
    userRole: state.user.role,
    menu: state.menu,
  }))(Layout)
);

export { LayoutContainer as Layout };
