import React from "react";
import { Panel } from "shared/components";
import PrintIcon from "mdi-react/PrinterIcon";
//import domtoimage from "dom-to-image";

const Html = (props) => {
  const { title, titleNavigationPath, hidePanel, showPrint, html } = props.data;

  const ref = React.useRef(null);

  const onPrint = () => {
    // domtoimage.toPng(ref.current).then(function (dataUrl) {
    //   const printWindow = window.open("", "PRINT"); //, "height=600,width=400");
    //   printWindow.document.write(`<html><head>`);
    //   printWindow.document.write(`<style type="text/css" media="print">@page {
    //    size: auto;
    //    margin: 0;
    //  }
    //  </style>`);
    //   printWindow.document.write("</head>");
    //   printWindow.document.write('<body">');
    //   printWindow.document.write(
    //     `<img src="${dataUrl}" width="1000" alt="picture">`
    //   );
    //   printWindow.document.write("</body></html>");
    //   //printWindow.document.close();
    //   printWindow.focus();
    //   printWindow.print();
    // });

    const printWindow = window.open("", "PRINT", "height=400,width=600");

    printWindow.document.write(`<html><head>`);
    printWindow.document.write(`<style type="text/css" media="print">@page {
      size: auto;
      margin: 0;
    }
    </style>`);
    printWindow.document.write("</head>");
    printWindow.document.write("<body >");
    printWindow.document.write(ref.current.innerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    //printWindow.close();
    return true;
  };

  return (
    <>
      {hidePanel && hidePanel.toUpperCase() === "X" ? (
        <>
          {showPrint ? (
            <div className="panel__btns">
              <button className="panel__btn" type="button" onClick={onPrint}>
                <PrintIcon />
              </button>
            </div>
          ) : null}
          <div ref={ref} dangerouslySetInnerHTML={{ __html: html }}></div>
        </>
      ) : (
        <Panel
          title={title && title !== "" ? title : null}
          titleNav={
            titleNavigationPath && titleNavigationPath !== ""
              ? titleNavigationPath
              : null
          }
        >
          {showPrint ? (
            <div className="panel__btns">
              <button className="panel__btn" type="button" onClick={onPrint}>
                <PrintIcon />
              </button>
            </div>
          ) : null}
          <div ref={ref} dangerouslySetInnerHTML={{ __html: html }}></div>
        </Panel>
      )}
    </>
  );
};

export default Html;
