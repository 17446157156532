import L from "leaflet";

L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
  onAdd: function (map) {
    L.TileLayer.WMS.prototype.onAdd.call(this, map);
    map.on("click", this.getFeatureInfo, this);
  },

  onRemove: function (map) {
    L.TileLayer.WMS.prototype.onRemove.call(this, map);
    map.off("click", this.getFeatureInfo, this);
  },

  getFeatureInfo: function (evt) {
    const url = this.getFeatureInfoUrl(evt.latlng);
    const showResults = L.Util.bind(this.showGetFeatureInfo, this);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        showResults(null, evt.latlng, data);
      })
      .catch(err => {
        // could not get the feature info...
      })
  },

  getFeatureInfoUrl: function (latlng) {
    const point = this._map.latLngToContainerPoint(latlng, this._map.getZoom());
    const size = this._map.getSize();
    let params = {};

    if (this.wmsParams.version === "1.3" || this.wmsParams.version === "1.3.0") {
      params = {
        request: "GetFeatureInfo",
        styles: this.wmsParams.styles,
        transparent: true,
        version: this.wmsParams.version,
        format: this.wmsParams.format,
        bbox: this._map.getBounds().toBBoxString(),
        height: size.y.toFixed(0),
        width: size.x.toFixed(0),
        layers: this.wmsParams.layers,
        query_layers: this.wmsParams.layers,
        CRS: "CRS:84",
        info_format: "application/json",
        service: "WMS"
      };
      params["i"] = point.x;
      params["j"] = point.y;
    } else {
      params = {
        request: 'GetFeatureInfo',
        service: 'WMS',
        srs: 'EPSG:4326',
        styles: this.wmsParams.styles,
        transparent: this.wmsParams.transparent,
        version: this.wmsParams.version,
        format: this.wmsParams.format,
        bbox: this._map.getBounds().toBBoxString(),
        height: size.y.toFixed(0),
        width: size.x.toFixed(0),
        layers: this.wmsParams.layers,
        query_layers: this.wmsParams.layers,
        info_format: 'application/json',
      };
      params['x'] = point.x;
      params['y'] = point.y;
    }

    
    return this._url + L.Util.getParamString(params, this._url, true);
  },

  showGetFeatureInfo: function (err, latlng, content) {
    if (!content || !content.features || !content.features.length) {
      return;
    }
    const props = Object.keys(content.features[0].properties);
    const html = props
      .map((prop) => {
        return `<b>${prop}</b>: ${content.features[0].properties[prop]}`;
      })
      .join("<br />");

    L.popup({ maxWidth: 800 })
      .setLatLng(latlng)
      .setContent(html)
      .openOn(this._map);
  },
});

L.tileLayer.betterWms = function (url, options) {
  return new L.TileLayer.BetterWMS(url, options);
};
