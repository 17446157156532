import React from 'react';
import { Link } from 'react-router-dom';

const TopbarMenuLinkItem = (props) => {
  const { title, icon, path, onClick } = props;

  return (
    <Link className="topbar__link" to={path} onClick={onClick}>
      <span className={`topbar__link-icon lnr lnr-${icon}`} />
      <p className="topbar__link-title">{title}</p>
    </Link>
  );
};

export default TopbarMenuLinkItem;
