import React from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import Alert from "shared/components/Alert";
import LoadingIcon from "mdi-react/LoadingIcon";
import AutorenewIcon from "mdi-react/AutorenewIcon";

const Panel = (props) => {
  const {
    t,
    title,
    subtitle,
    children,
    isLoading,
    onRefresh,
    error,
    titleNav,
  } = props;

  const contentLoading = isLoading ? (
    <div className="panel__refresh">
      <LoadingIcon />
    </div>
  ) : null;


  const contentHeaderButtons = (
    <div className="panel__btns">
      {onRefresh ? (
        <button className="panel__btn" type="button" onClick={onRefresh}>
          <AutorenewIcon />
        </button>
      ) : null}
    </div>
  );

  const contentHeaderTitle = title ? (
    <div className="panel__title">
      <h5 className="bold-text">
        {titleNav ? <Link to={titleNav}>{title}</Link> : title}
      </h5>
      <h5 className="subhead">{subtitle}</h5>
    </div>
  ) : null;

  const contentBodyError = (
    <div className="panel__content">
      <Alert color="danger" className="alert--bordered" icon>
        <p>
          <span className="bold-text">{t("error")}</span>
          <br /> {error ? error.message : null}
        </p>
      </Alert>
    </div>
  );

  const contentBody = (
    <div
      className="panel__content"
      style={error || isLoading ? { display: "none" } : null}
    >
      {children}
    </div>
  );

  return (
    <Card>
      <CardBody className="panel__body">
        {contentLoading}
        {contentHeaderButtons}
        {contentHeaderTitle}
        {contentBody}
        {error ? contentBodyError : null}
      </CardBody>
    </Card>
  );
};

export default withTranslation("common")(Panel);
