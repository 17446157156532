import React from "react";
import { API } from "aws-amplify";
import i18next from "i18next";
import store from "redux/store";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

export function getData(method, parameters) {
  const params = parameters || {};
  params.LANGUAGE = i18next.language || "EN";

  let currentRole = store.getState().user.role.selection;
  if (currentRole) {
    params.ROLE = currentRole.roleId;
  } else {
    params.ROLE = "";
  }

  params.IS_MOBILE = isMobile ? "X" : "-";

  return API.post("teeltportaal", "/sap_exec", {
    body: {
      function: method,
      params,
    },
  });
}

export function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getMinutesUntilNow(date1) {
  var diff = Math.abs(new Date() - date1);
  return diff / 1000 / 60;
}

export function requiresUpdate(obj, filter) {
  if (!obj || !obj.last_update) {
    return true;
  }
  if (
    (filter || obj.filter) &&
    JSON.stringify(filter) !== JSON.stringify(obj.filter)
  ) {
    return true;
  }

  return getMinutesUntilNow(obj.last_update) > 5;
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function displayTextAndKey(text, key) {
  return text + " (" + key + ")";
}

export function abapTextToHtml(value) {
  if (!value) {
    return;
  }

  let specials = value.split("$actions$");
  let text = specials.length ? specials[0] : "";

  let specialItems = {};
  if (specials && specials.length && specials.length > 1) {
    const si = specials[1].split("||");
    for (let i = 0; i < si.length; i++) {
      var props = si[i].split("=");
      specialItems[props[0]] = props[1];
    }
  }

  let lines = text.split("\\n").map((item, index) => {
    return (
      <span key={`table-cell-span-${index + 1}`} style={{ display: "block" }}>
        {item}
      </span>
    );
  });


  if (specialItems.link && specialItems.link !== "") {
    return (
      <Link
        to={specialItems.link}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {lines}
      </Link>
    );
  } else if (specialItems.tel && specialItems.tel !== "") {
    return (
      <Link
        onClick={(e) => {
          window.open("tel:" + specialItems.tel, "_new");
          e.stopPropagation();
        }}
      >
        {lines}
      </Link>
    );
  } else if (specialItems.html && specialItems.html === "X") {
    return <div dangerouslySetInnerHTML={{ __html: lines[0].props.children }}></div>;
  } else {
    return <div>{lines}</div>;
  }
}

export function getMuiTableTexts(t) {
  return {
    body: {
      noMatch: t("table.muiTableBodyNoMatch"),
      toolTip: t("table.muiTableBodyTooltip"),
      columnHeaderTooltip: (column) =>
        `${t("table.muiTableBodyColumnTooltip")} ${column.label}`,
    },
    pagination: {
      next: t("table.muiTablePaginationNext"),
      previous: t("table.muiTablePaginationPrevious"),
      rowsPerPage: t("table.muiTablePaginationRowsPerPage"),
      displayRows: t("table.muiTablePaginationDisplayRows"),
    },
    toolbar: {
      search: t("table.muiTableToolbarSearch"),
      downloadCsv: t("table.muiTableToolbarDownloadCsv"),
      print: t("table.muiTableToolbarPrint"),
      viewColumns: t("table.muiTableToolbarViewColumns"),
      filterTable: t("table.muiTableToolbarFilterTable"),
    },
    filter: {
      all: t("table.muiTableFilterAll"),
      title: t("table.muiTableFilterTitle"),
      reset: t("table.muiTableFilterReset"),
    },
    viewColumns: {
      title: t("table.muiTableViewColumnsTitle"),
      titleAria: t("table.muiTableViewColumnsTitleAria"),
    },
    selectedRows: {
      text: t("table.muiTableViewSelectedRowsText"),
      delete: t("table.muiTableViewSelectedRowsDelete"),
      deleteAria: t("table.muiTableViewSelectedRowsDeleteAria"),
    },
  };
}
