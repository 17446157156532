import React from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const Breadcrumb = (props) => {
  let breadcrumbItems = null;
  if (props.data && props.data.length) {
    breadcrumbItems = props.data.map((item, index) => {
      if (item.path && item.path !== '') {
        return (
          <AntBreadcrumb.Item key={`breadcrumb-${index + 1}`}>
            <Link to={item.path}>{item.name}</Link>
          </AntBreadcrumb.Item>
        );
      } else {
        return <AntBreadcrumb.Item key={`breadcrumb-${index + 1}`}>{item.name}</AntBreadcrumb.Item>;
      }
    });
  }

  return (
    <div style={{ height: 60 }}>
      <AntBreadcrumb>{breadcrumbItems}</AntBreadcrumb>
    </div>
  );
};

export default Breadcrumb;
