import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Button as AntButton } from 'antd';

const ResetPasswordForm = (props) => {
  const { t, pendingConfirmation, errorMessage, onResetPassword, onConfirmation, onCancel } = props;

  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onCancelClick = () => {
    onCancel();
  };

  const onChangeInputEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangeInputConfirmationCode = (e) => {
    setConfirmationCode(e.target.value);
  };

  const onChangeInputNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const onChangeInputConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onClickButtonResetPassword = () => {
    onResetPassword(email);
  };

  const onClickButtonConfirmation = () => {
    if (newPassword !== confirmPassword) {
      alert(t('reset-password.password-mismatch'));
      return;
    }
    onConfirmation(email, confirmationCode, newPassword);
  };

  const formEmailRequest = (
    <>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-email">
          {t('reset-password.email')}
        </Label>
        <Input type="email" name="email" id="form-input-email" placeholder={t('reset-password.email-placeholder')} autoFocus={true} value={email} onChange={onChangeInputEmail} />
      </FormGroup>

      <Button className="account__btn" type="button" onClick={onClickButtonResetPassword} color="primary" style={{ marginTop: 30 }}>
        {t('reset-password.reset-password')}
      </Button>
    </>
  );

  const formConfirmation = (
    <>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-confirmation-code">
          {t('reset-password.confirmation-code')}
        </Label>
        <Input
          type="number"
          name="confirmation_code"
          id="form-input-confirmation-code"
          placeholder={t('reset-password.confirmation-code-placeholder')}
          value={confirmationCode}
          onChange={onChangeInputConfirmationCode}
        />
      </FormGroup>
      <hr style={{ width: '100%' }} />
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-new_password">
          {t('reset-password.new-password')}
        </Label>
        <Input
          type="password"
          name="new_password"
          id="form-input-new_password"
          placeholder={t('reset-password.new-password-placeholder')}
          value={newPassword}
          onChange={onChangeInputNewPassword}
        />
      </FormGroup>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-confirm_password">
          {t('reset-password.confirm-password')}
        </Label>
        <Input
          type="password"
          name="confirm_password"
          id="form-input-confirm_password"
          placeholder={t('reset-password.confirm-password-placeholder')}
          value={confirmPassword}
          onChange={onChangeInputConfirmPassword}
        />
      </FormGroup>

      <Button className="account__btn" type="button" onClick={onClickButtonConfirmation} color="primary" style={{ marginTop: 30 }}>
        {t('reset-password.confirm')}
      </Button>
    </>
  );

  return (
    <Form className="form" style={{ width: 400 }}>
      {errorMessage && errorMessage !== '' ? (
        <FormGroup className="form__form-group">
          <Label className="form__form-group-label">{errorMessage}</Label>
        </FormGroup>
      ) : null}

      {pendingConfirmation ? formConfirmation : formEmailRequest}

      <div className="account__cancel" style={{ bottom: 0, position: 'relative', marginTop: 20 }}>
        <AntButton type="link" onClick={onCancelClick} style={{ fontSize: 11 }}>
          {t('reset-password.cancel')}
        </AntButton>
      </div>
    </Form>
  );
};

export default withTranslation('common')(ResetPasswordForm);
