import React from 'react';
import { DividerInformation, Panel } from 'shared/components';

const InformationDividier = (props) => {
  let data = {
    groups: [],
    items: [],
  };

  if (props.data && props.data.items && props.data.items.length) {
    for (var i = 0; i < props.data.items.length; i++) {
      const item = props.data.items[i];
      if (!data.groups.some((group) => group.id === item.groupId)) {
        data.groups.push({
          id: item.groupId,
          label: item.groupLabel,
        });
      }
      data.items.push(item);
    }
  }

  return (
    <Panel
      title={props.data.title && props.data.title !== '' ? props.data.title : null}
      titleNav={props.data.titleNavigationPath && props.data.titleNavigationPath !== '' ? props.data.titleNavigationPath : null}
    >
      <DividerInformation data={data} />
    </Panel>
  );
};

export default InformationDividier;
