import React from 'react';
import history from 'containers/App/history';
import Image404 from 'shared/img/404/404.png';
import Topbar from 'shared/components/Topbar';
import { Button } from 'antd';

const NotFound = (props) => {
  const handleBackClick = () => {
    history.goBack();
  };

  const handleHomeClick = () => {
    history.push('/');
  };

  return (
    <>
      <Topbar />
      <div className="not-found">
        <div className="not-found__content">
          <img className="not-found__image" src={Image404} alt="404" />
          <h3 className="not-found__info">Ooops! The page you are looking for could not be found :(</h3>
          <Button type="primary" onClick={handleHomeClick} style={{ margin: 10 }}>
            Home
          </Button>
          <Button type="primary" onClick={handleBackClick} style={{ margin: 10 }}>
            Back
          </Button>
        </div>
      </div>
    </>
  );
};

export { NotFound };
