import React from 'react';
import SidebarLink from './SidebarLink';
import { Divider } from 'antd';

const SidebarContent = (props) => {
  const { onClick, menuItems } = props;

  const hideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {menuItems.map((item, index) => {
          if (item.name.toLowerCase() === 'divider') {
            return <Divider />;
          } else {
            return <SidebarLink key={`MENU-${index + 1}`} title={item.name} icon={item.icon} route={item.path} onClick={hideSidebar} />;
          }
        })}
      </ul>
    </div>
  );
};

export default SidebarContent;
