import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import appReducer from './reducers/app';
import authReducer from './reducers/auth';
import pagesReducer from './reducers/pages';
import menuReducer from './reducers/menu/menuReducer';
import userReducer from './reducers/user';
import mapReducer from './reducers/map/mapReducer';
import { LOGOUT_AUTHLOGIN_SUCCESS } from './actions/auth/loginActions';

const combinedReducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  pages: pagesReducer,
  menu: menuReducer,
  user: userReducer,
  map: mapReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_AUTHLOGIN_SUCCESS || action.type === 'CHANGE_ROLE') {
    state = undefined;
  }
  return combinedReducers(state, action);
};

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)));

export default store;
