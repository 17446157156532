import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'containers/Layout';

const AuthenticatedRoute = ({ component: C, appProps, pageProps, ...rest }) => {
  const renderComponent = (props) => {
    return appProps.userRole.selection ? (
      <>
        <Layout />
        <div className="container__wrap">
          <C {...props} pageSettings={{ ...pageProps }} />
        </div>
      </>
    ) : (
      <Redirect to={`/auth/roles?redirect=${props.location.pathname}${props.location.search}`} />
    );
  };

  return (
    <Route
      {...rest}
      render={(props) => (appProps.authLogin.isAuthenticated ? renderComponent(props) : <Redirect to={`/session?redirect=${props.location.pathname}${props.location.search}`} />)}
    />
  );
};

export default AuthenticatedRoute;
