import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import MainWrapper from './components/MainWrapper';
import AuthenticatedRoute from './components/AuthenticatedRoute';

import { Landing } from 'containers/Landing';
import { Session } from 'containers/Session';
import { Login } from 'containers/Auth/Login';
import { ResetPassword } from 'containers/Auth/ResetPassword';
import { ChangePassword } from 'containers/Auth/ChangePassword';
import { UserRoleSelect } from 'containers/UserRoleSelect';
import { ChangeEmail } from 'containers/Auth/ChangeEmail';
import { NotFound } from 'containers/NotFound';
import { PageTemplate } from 'containers/PageTemplate';
import { Loading } from 'shared/components';

const Router = (props) => {
  const location = useLocation();

  let pageRoutes = [];
  if (props.pages.paths && props.pages.paths.length) {
    pageRoutes = props.pages.paths.map((item, index) => {
      return (
        <AuthenticatedRoute
          key={`route-${index + 1}`}
          exact
          path={item.path}
          component={PageTemplate}
          pageProps={{ ...item }}
          appProps={{ authLogin: props.authLogin, userRole: props.userRole }}
        />
      );
    });
  }

  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route
            exact
            path="/"
            render={(appProps) => {
              return props.authLogin.isAuthenticated ? (
                <Redirect to={props.pages.paths && props.pages.paths.length ? props.pages.paths[0].path : '/not_found'} />
              ) : (
                <Landing {...props} />
              );
            }}
          />
          <Route exact path="/landing" component={Landing} />
          <Route exact path="/session" component={Session} />

          <Route exact path="/auth/login" component={Login} />
          <Route exact path="/auth/reset_password" component={ResetPassword} />
          <Route
            exact
            path="/auth/change_password"
            render={() => {
              return props.authLogin.isAuthenticated ? <ChangePassword /> : <Redirect to={`/`} />;
            }}
          />
          <Route
            exact
            path="/auth/change_email"
            render={() => {
              return props.authLogin.isAuthenticated ? <ChangeEmail /> : <Redirect to={`/`} />;
            }}
          />
          <Route exact path="/auth/roles" component={UserRoleSelect} />
          <Route exact path="not_found" component={NotFound} />

          <AuthenticatedRoute exact path="/loading" component={Loading} appProps={{ authLogin: props.authLogin, userRole: props.userRole }} />

          {pageRoutes}

          <Route
            render={() => {
              if (location.pathname && location.pathname !== '') {
                if (!props.userRole.selection) {
                  return <Redirect to={`/auth/roles?redirect=${location.pathname}`} />;
                } else {
                  return <Route component={NotFound} />;
                }
              } else {
                return <Route component={NotFound} />;
              }
            }}
          />
        </Switch>
      </main>
    </MainWrapper>
  );
};

const mapStateToProps = (state) => ({
  authLogin: state.auth.login,
  userRole: state.user.role,
  pages: state.pages,
});

const RouterContainer = connect(mapStateToProps)(Router);

export { RouterContainer as AppRouter };
