import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { App } from './containers/App';
import * as serviceWorker from './serviceWorker';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.min.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'libs/leaflet-betterwms/leaflet-better-wms';
import 'leaflet-easybutton/'
import 'leaflet-easybutton/src/easy-button.css'

import 'font-awesome/css/font-awesome.min.css';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITYPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APPCLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'teeltportaal',
        endpoint: process.env.REACT_APP_AWS_API_ENDPOINT,
        region: process.env.REACT_APP_AWS_API_REGION,
      },
      {
        name: 'esritoken',
        endpoint: 'https://t7w6kiiq4d.execute-api.eu-central-1.amazonaws.com/dev',
        region: 'eu-central-1',
      }
    ],
  },
});

// fix for leaflet images
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [24, 36],
  iconAnchor: [12, 36],
});

L.Marker.prototype.options.icon = DefaultIcon;

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
