import React from 'react';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';

const Topbar = (props) => {
  const { changeMobileSidebarVisibility, changeSidebarVisibility, changeRole, changeLanguage, logout, username, role } = props;

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} changeSidebarVisibility={changeSidebarVisibility} />
          <Link className="topbar__logo" to="/" />
        </div>
        <div className="topbar__right">
          <TopbarProfile logout={logout} changeRole={changeRole} changeLanguage={changeLanguage} username={username} role={role} />
        </div>
      </div>
    </div>
  );
};

export default Topbar;
