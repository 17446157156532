import React from 'react';

const Subheader = (props) => {
  const { data } = props;

  let table = <div style={{ marginBottom: 25 }} />;
  if (data && data.length) {
    table = (
      <table style={{ marginBottom: 25 }}>
        <tbody style={{ verticalAlign: 'top' }}>
          {data.map((item, index) => {
            return (
              <tr key={`sumtable-row-${index + 1}`}>
                <td><div dangerouslySetInnerHTML={{ __html: item.label }}></div></td>
                <td style={{ paddingLeft: 15 }}><div dangerouslySetInnerHTML={{ __html: item.value }}></div></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return table;
};

export default Subheader;
