import { combineReducers } from 'redux';
import pathReducer from './pathReducer';
import pageReducer from './pageReducer';

const pagesReducers = combineReducers({
  paths: pathReducer,
  page: pageReducer
});

export default pagesReducers;
