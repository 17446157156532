import React from "react";
import { withTranslation } from "react-i18next";
import { getMuiTableTexts } from "utils";
import history from "containers/App/history";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { abapTextToHtml } from "utils";
import { isMobile } from "react-device-detect";
import { Popover, Space } from "antd";
import { InfoCircleTwoTone } from '@ant-design/icons';


const TableList = (props) => {
  const { t, data, columns, config, onSelectRow, customClass } = props;

  let filter = false;
  if (config && config.filter) {
    filter = config.filter === "X";
  }

  let pagination = false;
  if (config && config.pagination) {
    pagination = config.pagination === "X";
  }

  let download = false;
  if (config && config.download) {
    download = config.download === "X";
  }

  let search = false;
  if (config && config.search) {
    search = config.search === "X";
  }

  let print = false;
  if (config && config.print) {
    print = config.print === "X";
  }

  let tableColumns = [];
  if (columns && columns.length) {
    tableColumns = columns.map((column) => {
      let display = false;
      if (column.displayBrowser && column.displayMobile) {
        if (isMobile) {
          display = column.displayMobile;
        } else {
          display = column.displayBrowser;
        }
      } else {
        display = column.display;
      }

      return {
        name: column.name,
        label: column.label,
        options: {
          display: display,
          customBodyRenderLite: (dataIndex) => {
            if (
              data[dataIndex][column.popupColumn] &&
              data[dataIndex][column.popupColumn] !== ""
            ) {
              return (
                <Popover
                  content={()=> { return <div dangerouslySetInnerHTML={{__html: data[dataIndex][column.popupColumn]}} /> }}
                  title={column.popupTitle}
                >
                  <Space size="small" >{abapTextToHtml(data[dataIndex][column.name])} <InfoCircleTwoTone color="blue" /></Space>
                </Popover>
              );
            } else {
              return abapTextToHtml(data[dataIndex][column.name]);
            }
          },
        },
      };
    });
  }

  let handleClickRow = null;
  if (onSelectRow) {
    handleClickRow = (rowData, rowMeta) => {
      onSelectRow(data[rowMeta.dataIndex]);
    };
  }

  if (
    !handleClickRow &&
    config &&
    config.navigationPath &&
    config.navigationPath !== ""
  ) {
    handleClickRow = (rowData, rowMeta) => {
      history.push(
        config.navigationPath.replaceAll(":id", data[rowMeta.dataIndex].id)
      );
    };
  }

  let responsiveDesktop = "vertical";
  let muiMaxHeight = "none";
  if (config && config.maxHeight && config.maxHeight !== "") {
    responsiveDesktop = "scrollMaxHeight";

    if (config.maxHeight.substring(0, 13).toLowerCase() === "windowheight-") {
      try {
        muiMaxHeight =
          window.innerHeight - parseInt(config.maxHeight.substring(13));
        muiMaxHeight = `${muiMaxHeight}px !important`;
      } catch (err) {}
    } else {
      try {
        muiMaxHeight = parseInt(config.maxHeight, 0) + "px !important";
      } catch (err) {}
    }
  }

  const options = {
    selectableRows: "none",
    responsive: isMobile ? "simple" : responsiveDesktop,
    fixedHeader: true,
    filter,
    pagination,
    download,
    downloadOptions: {
      filename: "download.csv",
      seperator: ";",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    search,
    print,
    viewColumns:
      columns &&
      columns.length &&
      columns.some((item) => item.display === "false"),
    elevation: 0,
    textLabels: getMuiTableTexts(t),
    onRowClick:
      config && config.navigationPath && config.navigationPath
        ? handleClickRow
        : null,
  };

  const getMobileMuiTheme = () => {
    return createMuiTheme({});
  };

  const getDesktopMuiTheme = () => {
    return createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            maxHeight: muiMaxHeight, // window.innerHeight - 246 + 'px !important',
          },
        },
      },
    });
  };

  return (
    <MuiThemeProvider
      theme={isMobile ? getMobileMuiTheme() : getDesktopMuiTheme()}
    >
      <MUIDataTable
        className={customClass || ""}
        data={data}
        columns={tableColumns}
        options={options}
      />
    </MuiThemeProvider>
  );
};

export default withTranslation("common")(TableList);
