import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import nl from 'shared/img/language/nl.png';
import en from 'shared/img/language/en.png';
import fr from 'shared/img/language/fr.png';
import de from 'shared/img/language/de.png';

const Topbar = (props) => {
  const { i18n } = props;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div style={{ marginLeft: 60 }} className="topbar__left">
          <Link className="topbar__logo" to="/" />
        </div>
        <div className="topbar__right">
          <button className="topbar__btn" type="button" onClick={() => changeLanguage('nl')}>
            <span className="topbar__language-btn-title">
              <img src={nl} alt="nl" />
            </span>
          </button>
          <button className="topbar__btn" type="button" onClick={() => changeLanguage('en')}>
            <span className="topbar__language-btn-title">
              <img src={en} alt="en" />
            </span>
          </button>
          <button className="topbar__btn" type="button" onClick={() => changeLanguage('de')}>
            <span className="topbar__language-btn-title">
              <img src={de} alt="de" />
            </span>
          </button>
          <button className="topbar__btn" type="button" onClick={() => changeLanguage('fr')}>
            <span className="topbar__language-btn-title">
              <img src={fr} alt="fr" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('common')(Topbar);
