import {
  GET_PAGE_REQUEST,
  GET_PAGE_FAILURE,
  GET_PAGE_SUCCESS,
  FORM_FIELDSUBMIT_REQUEST,
  FORM_FIELDSUBMIT_FAILURE,
  FORM_FIELDSUBMIT_SUCCESS,
  FORM_PAGESUBMIT_REQUEST,
  FORM_PAGESUBMIT_FAILURE,
  FORM_PAGESUBMIT_SUCCESS,
  ADD_PAGE_NOTIFICATIONS,
  DELETE_PAGE_NOTIFICATIONS,
} from "redux/actions/pages/pageActions";

const initialState = {};

export default function (state = initialState, action) {
  let page, newState;

  switch (action.type) {
    case GET_PAGE_REQUEST:
      page = { ...state };
      newState = {
        isFetching: true,
        data: null,
        error: null,
        last_update: null,
      };
      if (action.payload.id === undefined) {
        page[action.payload.page] = newState;
      } else {
        if (!page[action.payload.page]) {
          page[action.payload.page] = {};
        }
        page[action.payload.page][action.payload.id] = newState;
      }
      return page;

    case GET_PAGE_FAILURE:
      page = { ...state };
      newState = {
        isFetching: false,
        data: null,
        error: action.payload.error,
        last_update: null,
      };
      if (action.payload.id === undefined) {
        page[action.payload.page] = newState;
      } else {
        if (!page[action.payload.page]) {
          page[action.payload.page] = {};
        }
        page[action.payload.page][action.payload.id] = newState;
      }
      return page;

    case GET_PAGE_SUCCESS:
      page = { ...state };
      newState = {
        isFetching: false,
        data: action.payload.data || {},
        error: null,
        last_update: new Date(),
      };
      if (action.payload.id === undefined) {
        page[action.payload.page] = newState;
      } else {
        if (!page[action.payload.page]) {
          page[action.payload.page] = {};
        }
        page[action.payload.page][action.payload.id] = newState;
      }
      return page;

    case FORM_FIELDSUBMIT_REQUEST:
      page = { ...state };
      return page;

    case FORM_FIELDSUBMIT_FAILURE:
      return { ...initialState };

    case FORM_FIELDSUBMIT_SUCCESS:
      page = { ...state };
      return page;

    case FORM_PAGESUBMIT_REQUEST:
      page = { ...state };
      return page;

    case FORM_PAGESUBMIT_FAILURE:
      page = { ...state };
      return page;

    case FORM_PAGESUBMIT_SUCCESS:
      page = { ...state };
      return page;

    case ADD_PAGE_NOTIFICATIONS:
      page = { ...state };

      let pageData =
        action.payload.id === undefined
          ? page[action.payload.page].data
          : page[action.payload.page][action.payload.id].data;
      if (!pageData.notifications) {
        pageData.notifications = [...action.payload.notifications];
      } else {
        pageData.notifications = [
          ...pageData.notifications,
          ...action.payload.notifications,
        ];
      }
      return page;

    case DELETE_PAGE_NOTIFICATIONS:
      page = { ...state };

      if (action.payload.id === undefined) {
        if (
          page[action.payload.page].data &&
          page[action.payload.page].data.notifications &&
          page[action.payload.page].data.notifications.length
        ) {
          page[action.payload.page].data.notifications = [];
        }
      } else {
        if (
          page[action.payload.page][action.payload.id].data &&
          page[action.payload.page][action.payload.id].data.notifications &&
          page[action.payload.page][action.payload.id].data.notifications.length
        ) {
          page[action.payload.page][action.payload.id].data.notifications = [];
        }
      }
      return page;

    default:
      return state;
  }
}
