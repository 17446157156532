import { requiresUpdate, getData } from "utils";
import history from "containers/App/history";

export const GET_PAGE_CACHE = "GET_PAGE_CACHE";
export const GET_PAGE_REQUEST = "GET_PAGE_REQUEST";
export const GET_PAGE_FAILURE = "GET_PAGE_FAILURE";
export const GET_PAGE_SUCCESS = "GET_PAGE_SUCCESS";

export const ADD_PAGE_NOTIFICATIONS = "ADD_PAGE_NOTIFICATIONS";
export const DELETE_PAGE_NOTIFICATIONS = "DELETE_PAGE_NOTIFICATIONS";

export const FORM_FIELDSUBMIT_REQUEST = "FORM_FIELDSUBMIT_REQUEST";
export const FORM_FIELDSUBMIT_FAILURE = "FORM_FIELDSUBMIT_FAILURE";
export const FORM_FIELDSUBMIT_SUCCESS = "FORM_FIELDSUBMIT_SUCCESS";
export const FORM_PAGESUBMIT_REQUEST = "FORM_PAGESUBMIT_REQUEST";
export const FORM_PAGESUBMIT_FAILURE = "FORM_PAGESUBMIT_FAILURE";
export const FORM_PAGESUBMIT_SUCCESS = "FORM_PAGESUBMIT_SUCCESS";


export const pageActions = {
  get,
  refresh: fetch,
  formFieldSubmit,
  formPageSubmit,
  mapDrawSubmit,
  addPageNotifications,
  deletePageNotifications,
};

function get(page, id) {
  return (dispatch, getState) => {
    let fetchFromAPI = true;

    const pageState = getState().pages.page[page];

    if (pageState) {
      if (id === undefined) {
        if (pageState.data && pageState.data.disableCache === "X") {
          // disable the cache - always refresh data
        } else {
          fetchFromAPI = requiresUpdate(pageState);
        }
      } else {
        const pageItemState = pageState[id];
        if (pageItemState) {
          if (pageItemState.data && pageItemState.data.disableCache === "X") {
            // disable the cache - always refresh data
          } else {
            fetchFromAPI = requiresUpdate(pageItemState);
          }
        }
      }
    }
    if (fetchFromAPI) {
      return dispatch(fetch(page, id));
    } else {
      return dispatch(success(page, id));
    }
  };
  function success(page, id) {
    return { type: GET_PAGE_CACHE, page, id };
  }
}

function fetch(page, id) {
  return (dispatch) => {
    dispatch(request(page, id));

    // execute API
    let params = {};
    if (id !== undefined) {
      params.ID = id;
    }
    getData(page, params).then(
      (data) => dispatch(success(page, id, data)),
      (error) => dispatch(failure(page, id, error.response.data))
    );
  };
  function request(page, id) {
    return {
      type: GET_PAGE_REQUEST,
      payload: {
        page,
        id,
      },
    };
  }
  function failure(page, id, error) {
    return {
      type: GET_PAGE_FAILURE,
      payload: {
        page,
        id,
        error,
      },
    };
  }
  function success(page, id, data) {
    return {
      type: GET_PAGE_SUCCESS,
      payload: {
        page,
        id,
        data,
      },
    };
  }
}

function formFieldSubmit(field) {
  return (dispatch) => {
    dispatch(request());

    getData(field.api, {
      CONTEXT: field.context,
      FIELD: field.field,
      VALUE: field.value,
    }).then(
      (data) => dispatch(success(data)),
      (error) => {
        dispatch(failure(error.response.data));
      }
    );

    function request() {
      return {
        type: FORM_FIELDSUBMIT_REQUEST,
      };
    }
    function failure(error) {
      return {
        type: FORM_FIELDSUBMIT_FAILURE,
        payload: {
          error,
        },
      };
    }
    function success(data) {
      return {
        type: FORM_FIELDSUBMIT_SUCCESS,
        payload: {
          data,
        },
      };
    }
  };
}

function formPageSubmit(submitData) {
  return (dispatch) => {
    dispatch(request());

    getData(submitData.formApi, {
      CONTEXT: submitData.formContext,
      DATA: submitData.formData,
    }).then(
      (data) => {
        if (data.notifications && data.notifications.length) {
          dispatch(
            pageActions.addPageNotifications(
              submitData.pageApi,
              submitData.pageId,
              data.notifications
            )
          );
        }
        dispatch(success(data));

        if (data.redirect) {
          history.push(data.redirect);
        }
      },
      (error) => {
        dispatch(failure(error.response.data));
      }
    );

    function request() {
      return {
        type: FORM_PAGESUBMIT_REQUEST,
      };
    }
    function failure(error) {
      return {
        type: FORM_PAGESUBMIT_FAILURE,
        payload: {
          error,
        },
      };
    }
    function success(data) {
      return {
        type: FORM_PAGESUBMIT_SUCCESS,
        payload: {
          data,
        },
      };
    }
  };
}

function mapDrawSubmit(submitData) {
  return (dispatch) => {
    const data = submitData.mapData.map((item) => {
      return { GEOMETRY: JSON.stringify(item.geometry), TEXT: item.text}
    })

    getData(submitData.mapApi, {
      CONTEXT: submitData.mapContext,
      DATA: data,
    })
  };
}

function addPageNotifications(page, id, notifications) {
  return (dispatch) => {
    dispatch({
      type: ADD_PAGE_NOTIFICATIONS,
      payload: {
        page,
        id,
        notifications,
      },
    });
  };
}

function deletePageNotifications(page, id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_PAGE_NOTIFICATIONS,
      payload: {
        page,
        id,
      },
    });
  };
}
