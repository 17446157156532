import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import history from 'containers/App/history';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { Button as AntButton } from 'antd';

const ChangeEmailForm = (props) => {
  const { onChangeEmail, onConfirmation, errorMessage, t, pendingConfirmation } = props;
  const [newEmail, setNewEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');

  const onClickButtonChangeEmail = () => {
    onChangeEmail(newEmail);
  };

  const onClickButtonConfirmation = () => {
    onConfirmation(confirmationCode);
  };

  const onClickButtonCancel = () => {
    history.push('/');
  };

  const onChangeInputNewEmail = (e) => {
    setNewEmail(e.target.value);
  };

  const onChangeInputConfirmationCode = (e) => {
    setConfirmationCode(e.target.value);
  };

  const onKeyDownInputNewEmail = (event) => {
    if (event.key === 'Enter') {
      onChangeEmail(newEmail);
    }
  };

  const onKeyDownInputConfirmationCode = (event) => {
    if (event.key === 'Enter') {
      onConfirmation(confirmationCode);
    }
  };

  let formComponents = (
    <>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-new_email">
          {t('change-email.new-email')}
        </Label>
        <Input
          type="email"
          name="new_email"
          id="form-input-new_email"
          placeholder={t('change-email.new-email-placeholder')}
          autoFocus={true}
          value={newEmail}
          onChange={onChangeInputNewEmail}
          onKeyDown={onKeyDownInputNewEmail}
        />
      </FormGroup>
      <Button className="account__btn" style={{ marginTop: 30 }} type="button" onClick={onClickButtonChangeEmail} color="primary">
        {t('change-email.save')}
      </Button>
      <div className="account__cancel" style={{ bottom: 0, position: 'relative', marginTop: 20 }}>
        <AntButton type="link" onClick={onClickButtonCancel} style={{ fontSize: 11 }}>
          {t('change-email.cancel')}
        </AntButton>
      </div>
    </>
  );

  if (pendingConfirmation) {
    formComponents = (
      <>
        <FormGroup className="form__form-group">
          <Label className="form__form-group-label" for="form-input-validation_code">
            {t('change-email.validation-code')}
          </Label>
          <Input
            type="number"
            name="validation_code"
            id="form-input-validation_code"
            placeholder={t('change-email.validation-code-placeholder')}
            value={confirmationCode}
            onChange={onChangeInputConfirmationCode}
            onKeyDown={onKeyDownInputConfirmationCode}
          />
        </FormGroup>
        <Button className="account__btn" style={{ marginTop: 30 }} type="button" onClick={onClickButtonConfirmation} color="primary">
          {t('change-email.save')}
        </Button>
        <div className="account__cancel" style={{ bottom: 0, position: 'relative', marginTop: 20 }}>
          <AntButton type="link" onClick={onClickButtonCancel} style={{ fontSize: 11 }}>
            {t('change-email.cancel')}
          </AntButton>
        </div>
      </>
    );
  }

  return (
    <Form className="form">
      <Alert color="danger" isOpen={!!errorMessage}>
        {errorMessage}
      </Alert>
      {formComponents}
    </Form>
  );
};

export default withTranslation('common')(ChangeEmailForm);
