import { Auth } from 'aws-amplify';
import history from 'containers/App/history';
import { loginActions } from 'redux/actions/auth/loginActions';

export const CHANGE_AUTHPASSWORD_REQUEST = 'CHANGE_AUTHPASSWORD_REQUEST';
export const CHANGE_AUTHPASSWORD_SUCCESS = 'CHANGE_AUTHPASSWORD_SUCCESS';
export const CHANGE_AUTHPASSWORD_FAILURE = 'CHANGE_AUTHPASSWORD_FAILURE';

export const RESET_AUTHPASSWORD = 'RESET_AUTHPASSWORD';
export const RESET_AUTHPASSWORD_REQUEST = 'RESET_AUTHPASSWORD_REQUEST';
export const RESET_AUTHPASSWORD_SUCCESS = 'RESET_AUTHPASSWORD_SUCCESS';
export const RESET_AUTHPASSWORD_FAILURE = 'RESET_AUTHPASSWORD_FAILURE';

export const CONFIRM_AUTHPASSWORD_REQUEST = 'CONFIRM_AUTHPASSWORD_REQUEST';
export const CONFIRM_AUTHPASSWORD_SUCCESS = 'CONFIRM_AUTHPASSWORD_SUCCESS';
export const CONFIRM_AUTHPASSWORD_FAILURE = 'CONFIRM_AUTHPASSWORD_FAILURE';

export const passwordActions = {
  change,
  changeRequired,
  reset,
  resetPassword,
  confirm,
};

function change(oldPassword, newPassword) {
  return (dispatch) => {
    dispatch(request());

    Auth.currentAuthenticatedUser().then(
      (currentUser) => {
        Auth.changePassword(currentUser, oldPassword, newPassword).then(
          () => {
            dispatch(success());
            history.push('/');
          },
          (error) => {
            dispatch(failure(error));
          }
        );
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CHANGE_AUTHPASSWORD_REQUEST };
  }
  function success() {
    return { type: CHANGE_AUTHPASSWORD_SUCCESS };
  }
  function failure(error) {
    return {
      type: CHANGE_AUTHPASSWORD_FAILURE,
      payload: error,
    };
  }
}

function changeRequired(email, oldPassword, newPassword, rememberMe) {
  return (dispatch) => {
    dispatch(request());

    Auth.signIn(email, oldPassword).then(
      (user) => {
        Auth.completeNewPassword(user, newPassword)
          .then(() => {
            dispatch(success());
            dispatch(loginActions.logIn(email, newPassword, rememberMe));
          })
          .catch((error) => {
            dispatch(failure(error));
          });
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CHANGE_AUTHPASSWORD_REQUEST };
  }
  function success() {
    return { type: CHANGE_AUTHPASSWORD_SUCCESS };
  }
  function failure(error) {
    return {
      type: CHANGE_AUTHPASSWORD_FAILURE,
      payload: error,
    };
  }
}

function reset() {
  return (dispatch) => {
    dispatch({
      type: RESET_AUTHPASSWORD,
    });
  };
}

function resetPassword(email) {
  return (dispatch) => {
    dispatch(request());

    Auth.forgotPassword(email).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: RESET_AUTHPASSWORD_REQUEST };
  }
  function success() {
    return { type: RESET_AUTHPASSWORD_SUCCESS };
  }
  function failure(error) {
    return {
      type: RESET_AUTHPASSWORD_FAILURE,
      payload: error,
    };
  }
}

function confirm(email, code, password) {
  return (dispatch) => {
    dispatch(request());

    Auth.forgotPasswordSubmit(email, code, password).then(
      () => {
        dispatch(success());
        dispatch(loginActions.logIn(email, password, false));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CONFIRM_AUTHPASSWORD_REQUEST };
  }
  function success() {
    return { type: CONFIRM_AUTHPASSWORD_SUCCESS };
  }
  function failure(error) {
    return {
      type: CONFIRM_AUTHPASSWORD_FAILURE,
      payload: error,
    };
  }
}
