import React from 'react';
import { withTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({ title, icon, newLink, route, onClick, t }) => (
  <NavLink to={route} onClick={onClick} activeClassName="sidebar__link-active">
    <li className="sidebar__link">
      {/* {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''} */}
      {icon ? (
        <div style={{ lineHeight: '16px' }}>
          <i className={`${icon} sidebar__link-icon`} />
        </div>
      ) : null}
      <p className="sidebar__link-title">
        {title}
        {newLink ? (
          <Badge className="sidebar__link-badge">
            <span>{t('new')}</span>
          </Badge>
        ) : (
          ''
        )}
      </p>
    </li>
  </NavLink>
);

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => {},
};

export default withTranslation('common')(SidebarLink);
