import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Collapse, Descriptions, Button } from 'antd';

const ModalInfo = (props) => {
  const { t, data, title, visible, onOK } = props;

  let groupPanels = [];
  if (data && data.groups) {
    groupPanels = data.groups.map((group, groupIndex) => {
      let groupItems = data.items
        .filter((item) => {
          return item.group === group;
        })
        .map((item, itemIndex) => {
          return (
            <Descriptions.Item label={item.label} key={groupIndex + 1 + '-' + (itemIndex + 1)}>
              {item.value}
            </Descriptions.Item>
          );
        });

      return (
        <Collapse.Panel header={group} key={groupIndex + 1}>
          <Descriptions size="small" layout="vertical">
            {groupItems}
          </Descriptions>
        </Collapse.Panel>
      );
    });
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onOK}
      destroyOnClose={true}
      footer={[
        <Button key="close" type="primary" onClick={onOK}>
          {t('close')}
        </Button>,
      ]}
    >
      <Collapse defaultActiveKey={groupPanels.map((group, index) => index + 1)}>{groupPanels}</Collapse>
    </Modal>
  );
};

export default withTranslation('common')(ModalInfo);
