import Amplify, { Auth } from 'aws-amplify';
import { DateUtils } from '@aws-amplify/core';
import history from 'containers/App/history';

export const VALIDATE_AUTHLOGIN_REQUEST = 'VALIDATE_AUTHLOGIN_REQUEST';
export const VALIDATE_AUTHLOGIN_SUCCESS = 'VALIDATE_AUTHLOGIN_SUCCESS';
export const VALIDATE_AUTHLOGIN_FAILURE = 'VALIDATE_AUTHLOGIN_FAILURE';

export const LOGIN_AUTHLOGIN_REQUEST = 'LOGIN_AUTHLOGIN_REQUEST';
export const LOGIN_AUTHLOGIN_SUCCESS = 'LOGIN_AUTHLOGIN_SUCCESS';
export const LOGIN_AUTHLOGIN_FAILURE = 'LOGIN_AUTHLOGIN_FAILURE';

export const LOGIN_AUTHLOGIN_FORCEPASSWORDCHANGE = 'LOGIN_AUTHLOGIN_FORCEPASSWORDCHANGE';

export const LOGOUT_AUTHLOGIN_REQUEST = 'LOGOUT_AUTHLOGIN_REQUEST';
export const LOGOUT_AUTHLOGIN_SUCCESS = 'LOGOUT_AUTHLOGIN_SUCCESS';
export const LOGOUT_AUTHLOGIN_FAILURE = 'LOGOUT_AUTHLOGIN_FAILURE';

export const loginActions = {
  validate,
  logIn,
  logOut,
};

/* Check authentication */
function validate() {
  return (dispatch) => {
    dispatch(request());

    Auth.currentSession().then(
      (user) => {
        DateUtils.setClockOffset(user.clockDrift * 1000 * -1);
        dispatch(success(user.idToken.payload));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: VALIDATE_AUTHLOGIN_REQUEST };
  }
  function success(user) {
    return { type: VALIDATE_AUTHLOGIN_SUCCESS, payload: user };
  }
  function failure(error) {
    return {
      type: VALIDATE_AUTHLOGIN_FAILURE,
      payload: error,
    };
  }
}

/* Log in */
function logIn(email, password, rememberMe) {
  return (dispatch) => {
    dispatch(request({ email }));

    if (!email || email === '') {
      dispatch(failure({ message: 'No email provided.' }));
      return;
    }

    if (!password || password === '') {
      dispatch(failure({ message: 'No password provided.' }));
      return;
    }

    Amplify.configure({
      Auth: {
        storage: rememberMe ? window.localStorage : window.sessionStorage,
      },
    });

    Auth.signIn(email, password).then(
      (user) => {
        if (user && user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch(forcePasswordChange());
        } else {
          dispatch(success(user.attributes.name));
          history.push('/');
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: LOGIN_AUTHLOGIN_REQUEST, payload: user };
  }
  function forcePasswordChange() {
    return { type: LOGIN_AUTHLOGIN_FORCEPASSWORDCHANGE };
  }
  function success(user) {
    return { type: LOGIN_AUTHLOGIN_SUCCESS, payload: user };
  }
  function failure(error) {
    return {
      type: LOGIN_AUTHLOGIN_FAILURE,
      payload: error,
    };
  }
}

/* Log out */
function logOut() {
  localStorage.removeItem("portalRole");
  
  return (dispatch) => {
    dispatch(request());

    Auth.signOut().then(
      () => {
        dispatch(success());
        history.push('/');
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: LOGOUT_AUTHLOGIN_REQUEST,
    };
  }
  function success() {
    return { type: LOGOUT_AUTHLOGIN_SUCCESS };
  }
  function failure(error) {
    return {
      type: LOGOUT_AUTHLOGIN_FAILURE,
      error,
    };
  }
}
