import { API } from "aws-amplify";

export const GET_MAP_ESRITOKEN_CACHE = "GET_MAP_ESRITOKEN_CACHE";
export const GET_MAP_ESRITOKEN_REQUEST = "GET_MAP_ESRITOKEN_REQUEST";
export const GET_MAP_ESRITOKEN_FAILURE = "GET_MAP_ESRITOKEN_FAILURE";
export const GET_MAP_ESRITOKEN_SUCCESS = "GET_MAP_ESRITOKEN_SUCCESS";

export const mapActions = {
  getEsriToken,
};

function getEsriToken() {
  return (dispatch, getState) => {
    const esriToken = getState().map.esriToken;
    if (esriToken) {
      return dispatch(success(esriToken));
    }

    return dispatch(fetch());
  };
  function success(esriToken) {
    return { type: GET_MAP_ESRITOKEN_CACHE, esriToken };
  }
}

function fetch() {
  return (dispatch) => {
    dispatch(request());

    const apiName = "esritoken";
    const path = "/tvschakel_esri_token";
    const myInit = {
      headers: {
        "x-api-key": "lRxTh5oG7Z5FcImCvevjn4yl1h1awpeY5WWkZwPN",
      },
    };

    API.get(apiName, path, myInit)
      .then((data) => {
        dispatch(success(data.token));
      })
      .catch((error) => {
        dispatch(failure());
      });
  };
  function request() {
    return {
      type: GET_MAP_ESRITOKEN_REQUEST,
      payload: {},
    };
  }
  function failure() {
    return {
      type: GET_MAP_ESRITOKEN_FAILURE,
      payload: {},
    };
  }
  function success(esriToken) {
    return {
      type: GET_MAP_ESRITOKEN_SUCCESS,
      payload: {
        esriToken,
      },
    };
  }
}
