/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import history from 'containers/App/history';
import { Col, Row, Container } from 'reactstrap';

const Landing = (props) => {
  const { menu } = props;

  useEffect(() => {
    if (menu.items && menu.items.length) {
      history.push(menu.items[0].route);
    } else {
      history.push('/session');
    }
  }, [menu.items]);

  return (
    <div className="landing">
      <div className="landing__menu">
        <Container>
          <Row>
            <Col md={12}>Landing page... to be done</Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: state.menu,
});

const LandingContainer = compose(withTranslation('common'), connect(mapStateToProps))(Landing);

export { LandingContainer as Landing };
