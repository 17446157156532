import { SET_PATHS } from 'redux/actions/pages/pathActions';

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PATHS:
      return action.payload.pages;

    default:
      return state;
  }
}
