import React, { useState, useEffect } from "react";
import { Panel, Datepicker } from "shared/components";
import {
  Divider,
  Descriptions,
  Input,
  Switch,
  InputNumber,
  Rate,
  Select,
  Button,
  TimePicker
} from "antd";
import { connect } from "react-redux";
import { pageActions } from "redux/actions/pages/pageActions";
import moment from "moment";

const FormComp = (props) => {
  const { dispatch, api, id } = props;

  const initialState = props.data.items.reduce((obj, item) => {
    return { ...obj, [item.id]: item.value };
  }, {});

  const [groupComps, setGroupComps] = useState(null);
  const [formData, setFormData] = useState(initialState);

  const handleSubmitButtonClick = (e) => {
    if (props.data.config.saveMode !== "PAGE") {
      return;
    }
    const formContext = props.data.config.context.map((item) => {
      return {
        NAME: item.name,
        VALUE: item.value,
      };
    });

    const data = Object.keys(formData).map((key) => {
      return { NAME: key, VALUE: formData[key] };
    });

    dispatch(
      pageActions.formPageSubmit({pageApi: api, pageId : id, formApi: props.data.config.api, formContext, formData: data})
    );
  };

  useEffect(() => {
    const groups = {
      ids: [],
      items: [],
    };

    const formContext = props.data.config.context.map((item) => {
      return {
        NAME: item.name,
        VALUE: item.value,
      };
    });

    const handleInputChange = (formId, value) => {
      setFormData({ ...formData, [formId]: value });
    };

    const handleInputBlur = (formId, value) => {
      if (props.data.config.saveMode !== "FIELD") {
        return;
      }

      // check if the value has changed
      const field = props.data.items.filter((item) => item.id === formId)[0];
      if (field.value !== value) {
        field.value = value;
        dispatch(
          pageActions.formFieldSubmit({
            context: formContext,
            api: props.data.config.api,
            field: formId,
            value: value,
          })
        );
      }
    };

    const handleCheckboxChange = (formId, value) => {
      setFormData({ ...formData, [formId]: value ? "X" : "" });

      if (props.data.config.saveMode !== "FIELD") {
        return;
      }

      dispatch(
        pageActions.formFieldSubmit({
          context: formContext,
          api: props.data.config.api,
          field: formId,
          value: value ? "X" : "",
        })
      );
    };

    const handleTimeChange = (formId, value) => {
      setFormData({ ...formData, [formId]: value });

      if (props.data.config.saveMode !== "FIELD") {
        return;
      }

      dispatch(
        pageActions.formFieldSubmit({
          context: formContext,
          api: props.data.config.api,
          field: formId,
          value: value,
        })
      );
    };

    const handleRateChange = (formId, value) => {
      setFormData({ ...formData, [formId]: value });

      if (props.data.config.saveMode !== "FIELD") {
        return;
      }

      dispatch(
        pageActions.formFieldSubmit({
          context: formContext,
          api: props.data.config.api,
          field: formId,
          value: value,
        })
      );
    };

    const handleSelectChange = (formId, value) => {
      let selectValue = null;
      if (Array.isArray(value)) { 
        if (value.length > 0) {
          selectValue = value.join(";");
        }
      } else {
        selectValue = value;
      }

      setFormData({ ...formData, [formId]: selectValue});

      if (props.data.config.saveMode !== "FIELD") {
        return;
      }

      dispatch(
        pageActions.formFieldSubmit({
          context: formContext,
          api: props.data.config.api,
          field: formId,
          value: selectValue,
        })
      );
    };

    if (props.data.items && props.data.items.length) {
      for (let i = 0; i < props.data.items.length; i++) {
        const item = props.data.items[i];

        // get the unique groups
        let groupIndex = groups.ids.indexOf(item.groupId);
        if (groupIndex === -1) {
          groupIndex = groups.ids.push(item.groupId) - 1;
          groups.items.push({
            id: item.groupId,
            label: item.groupLabel,
            components: [],
          });
        }

        // dataEntry component
        let entryComp = null;
        if (props.data.config.editable !== "X") {
          entryComp = (
            <Descriptions.Item
              label={item.label}
              key={groupIndex + 1 + "-" + (i + 1)}
            >
              {item.value}
            </Descriptions.Item>
          );
        } else if (item.type === "DATE") {
          entryComp = (
            <Datepicker
              style={item.width > 0 ? { width: item.width + "px" } : null}
              value={
                formData[item.id] && formData[item.id] !== ""
                  ? moment(formData[item.id], "YYYY-MM-DD")
                  : undefined
              }
              disabled={item.disabled && item.disabled.toUpperCase() === "X"}
              onChange={(e, s) => {
                handleInputChange(item.id, s);
              }}
              onBlur={(e) => {
                handleInputBlur(item.id, e.target.value);
              }}
            />
          );
        } else if (item.type === "TIME") {
          entryComp = (
            <TimePicker
              disabled={item.disabled && item.disabled.toUpperCase() === "X"}
              value={
                formData[item.id] && formData[item.id]
                  ? moment(formData[item.id], "HH:mm")
                  : undefined
              }
              format="HH:mm"
              style={item.width > 0 ? { width: item.width + "px" } : null}
              minuteStep={5}
              onChange={(time, timeString) => {
                handleTimeChange(item.id, timeString);
              }}
            ></TimePicker>
          );
        } else if (item.type === "BOOLEAN") {
          entryComp = (
            <Switch
              style={item.width > 0 ? { width: item.width + "px" } : null}
              checked={formData[item.id] === "X"}
              disabled={item.disabled && item.disabled.toUpperCase() === "X"}
              onChange={(checked) => {
                handleCheckboxChange(item.id, checked);
              }}
            ></Switch>
          );
        } else if (item.type === "NUMBER") {
          entryComp = (
            <InputNumber
              onChange={(e) => {
                handleInputChange(item.id, e);
              }}
              onBlur={(e) => {
                handleInputBlur(item.id, e.target.value);
              }}
              style={item.width > 0 ? { width: item.width + "px" } : null}
              value={formData[item.id]}
              step={item.step}
              min={item.min === "" ? undefined : item.min}
              max={item.max === "" ? undefined : item.max}
              disabled={item.disabled && item.disabled.toUpperCase() === "X"}
            ></InputNumber>
          );
        } else if (item.type === "RATE") {
          entryComp = (
            <Rate
              onChange={(value) => {
                handleRateChange(item.id, value);
              }}
              style={item.width > 0 ? { width: item.width + "px" } : null}
              value={formData[item.id]}
              count={item.max !== "" ? item.max : 5}
              disabled={item.disabled && item.disabled.toUpperCase() === "X"}
            ></Rate>
          );
        } else if (item.choices && item.choices.length) {
          entryComp = (
            <Select
              style={item.width > 0 ? { width: item.width + "px" } : null}
              mode={item.multiple && item.multiple.toUpperCase() === 'X' ? "multiple": ""}
              allowClear={true}
              onChange={(value) => {
                handleSelectChange(item.id, value);
              }}
              value={formData[item.id] ? formData[item.id].split(";") : []}
            >
              {item.choices.map((choice) => {
                return (
                  <Select.Option
                    key={`${item.id}-${choice.key}`}
                    value={choice.key}
                  >
                    {choice.label}
                  </Select.Option>
                );
              })}
            </Select>
          );
        } else if (item.type === "TEXTAREA") {
          entryComp = (
            <Input.TextArea
              disabled={item.disabled && item.disabled.toUpperCase() === "X"}
              maxLength={item.length ? item.length : null}
              value={formData[item.id]}
              style={item.width > 0 ? { width: item.width + "px" } : null}
              rows={item.rows}
              onChange={(e) => {
                handleInputChange(item.id, e.target.value);
              }}
              onBlur={(e) => {
                handleInputBlur(item.id, e.target.value);
              }}
            />
          );
        } else {
          entryComp = (
            <Input
              disabled={item.disabled && item.disabled.toUpperCase() === "X"}
              maxLength={item.length ? item.length : null}
              value={formData[item.id]}
              style={item.width > 0 ? { width: item.width + "px" } : null}
              onChange={(e) => {
                handleInputChange(item.id, e.target.value);
              }}
              onBlur={(e) => {
                handleInputBlur(item.id, e.target.value);
              }}
            />
          );
        }

        // create the component
        let comp = null;
        comp = (
          <Descriptions.Item
            label={item.label}
            key={groupIndex + 1 + "-" + (i + 1)}
          >
            {entryComp}
          </Descriptions.Item>
        );
        groups.items[groupIndex].components.push(comp);
      }
    }

    const groupComponents = groups.items.map((group, groupIndex) => {
      return (
        <div
          key={`comp-dividerinfo-${groupIndex + 1}`}
          className="tvs-comp-divider-information"
        >
          <Divider key={`comp-divider-${groupIndex + 1}`}>
            {group.label}
          </Divider>
          <Descriptions
            key={`comp-description-${groupIndex + 1}`}
            size="small"
            layout={props.data.config.layout}
            column={{ xxl: 5, xl: 4, lg: 3, md: 2, sm: 1, xs: 1 }}
          >
            {group.components}
          </Descriptions>
        </div>
      );
    });

    setGroupComps(groupComponents);
  }, [props.data, formData, dispatch]);

  return (
    <Panel
      title={
        props.data.title && props.data.title !== "" ? props.data.title : null
      }
      titleNav={
        props.data.titleNavigationPath && props.data.titleNavigationPath !== ""
          ? props.data.titleNavigationPath
          : null
      }
    >
      <div className="tvs-comp-dynamicform">{groupComps}</div>

      {props.data.config.saveMode === "PAGE" && JSON.stringify(initialState) !== JSON.stringify(formData) ? (
        <>
          <br />
          <Button onClick={handleSubmitButtonClick}>{ !props.data.config.submitButtonLabel || props.data.config.submitButtonLabel  === "" ? "Submit" : props.data.config.submitButtonLabel }</Button>
        </>
      ) : null}
    </Panel>
  );
};

const Form = connect()(FormComp);

export default Form;
