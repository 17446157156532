import React from 'react';
import { withTranslation } from 'react-i18next';
import history from 'containers/App/history';
import { Form, FormGroup, Label, Input, Alert, Button } from 'reactstrap';
import { Button as AntButton } from 'antd';

const LogInForm = (props) => {
  const { onLogin, email, setEmail, password, setPassword, rememberMe, setRememberMe, errorMessage, t } = props;

  const handleUpdateEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleUpdatePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleUpdateRememberMe = (e) => {
    setRememberMe(!rememberMe);
  };

  const handleSubmit = () => {
    onLogin(email, password, rememberMe);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onLogin(email, password, rememberMe);
    }
  };

  const handleForgotPassword = (event) => {
    history.push('/auth/reset_password');
  };

  return (
    <Form className="form">
      <Alert color="danger" style={{ color: 'white', marginTop: -24, marginBottom: 20 }} isOpen={!!errorMessage}>
        {errorMessage}
      </Alert>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-email">
          {t('login.email')}
        </Label>
        <Input type="email" name="email" id="form-input-email" placeholder={t('login.email-placeholder')} autoFocus value={email} onChange={handleUpdateEmail} />
      </FormGroup>
      <FormGroup className="form__form-group">
        <Label className="form__form-group-label" for="form-input-password">
          {t('login.password')}
        </Label>
        <Input
          type="password"
          name="password"
          id="form-input-password"
          placeholder={t('login.password-placeholder')}
          onChange={handleUpdatePassword}
          value={password}
          onKeyDown={handleKeyDown}
        />
      </FormGroup>
      <FormGroup className="form__form-group">
        <div className="account__forgot-password">
          <AntButton type="link" onClick={handleForgotPassword} style={{ fontSize: 10, poiner: 'cursor' }}>
            {t('login.forgot-password')}
          </AntButton>
        </div>
      </FormGroup>
      <FormGroup className="form__form-group">
        <Input
          style={{ height: 'auto', width: 'auto', marginTop: 2, marginLeft: 0 }}
          type="checkbox"
          id="form-input-rememberme"
          onChange={handleUpdateRememberMe}
          value={rememberMe}
        />
        <Label style={{ marginLeft: 20 }} className="form__form-group-label">
          {t('login.remember-me')}
        </Label>
      </FormGroup>
      <Button className="account__btn" type="button" onClick={handleSubmit} color="primary">
        {t('login.login')}
      </Button>
    </Form>
  );
};

export default withTranslation('common')(LogInForm);
